import { Checkbox, Column, FileAttachment } from "best-common-react";
import React, { useEffect, useState } from "react";
import fulfillmentTypes from "../../../../constants/FulfillmentTypeConstants";
import { FileInfoDTO } from "../../../../types/FileInfo";
import { ActionProps } from "./ActionHandler";

const massageFile = (file: FileInfoDTO): File | undefined => {
  if (file) {
    const mediaType = file.mediaType ? file.mediaType : "application/pdf";
    const blob = new Blob([file.data], {
      type: mediaType,
    });
    return new File([blob], file.fileName, {
      type: mediaType,
    });
  } else {
    return undefined;
  }
};

const TicketSentToRecipientAction: React.FC<ActionProps> = ({ state, onStateChange }) => {
  const checked = !!state.ticketSentToRecipient;
  const [file, setFile] = useState<File | undefined>();

  const addFiles = (files: File[]) => {
    if (files?.length) {
      const tempFile: File = files[0];
      const reader: FileReader = new FileReader();
      reader.onload = () => {
        const ticketFile: FileInfoDTO = {
          fileId: null,
          fileName: tempFile.name,
          filePrefix: "",
          fileExt: "",
          data: null,
          dataString: reader.result as string,
          mediaType: tempFile.type,
        };
        onStateChange({ ...state, ticketSentToRecipient: true, ticketFile: ticketFile });
      };
      reader.readAsDataURL(tempFile);
    } else {
      onStateChange({ ...state, ticketSentToRecipient: false, ticketFile: undefined });
    }
  };

  useEffect(() => {
    if (state.ticketFile && state.ticketFile.fileName) {
      setFile(massageFile(state.ticketFile));
    } else {
      setFile(undefined);
    }
  }, [state.ticketFile]);

  return (
    !!state.fulfillmentTypeId &&
    state.fulfillmentTypeId !== fulfillmentTypes.NONE && (
      <Column width={1} className="bcr-mt-1">
        <div>
          <Checkbox
            id="tickets-delivered-checkbox"
            label="Tickets Delivered"
            className="bcr-me-1"
            checked={checked}
            onChange={() => onStateChange({ ...state, ticketSentToRecipient: !checked })}
            gutterBottom
          />
          <FileAttachment
            id="tickets-delivered"
            disabled={false}
            maxFiles={1}
            onChange={addFiles}
            files={!!file ? [file] : []}
            gutterBottom
          />
        </div>
      </Column>
    )
  );
};

export default TicketSentToRecipientAction;
