import { Loader, Title } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAdminPurchaseOpportunityEntriesForUser } from "../../api/RequesTixApi";
import { AdminPurchaseOpportunityEntryDTO, PurchaseOpportunityDTO } from "../../types/PurchaseOpportunity";
import AdminPurchaseOpportunityGameForm from "./AdminPurchaseOpportunityGameForm";

const GamesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type AdminPurchaseOpportunityRequestActionsProps = {
  opportunity: PurchaseOpportunityDTO;
  userId: number | string;
  formChanged: boolean;
};

const AdminPurchaseOpportunityRequestActions: React.FC<AdminPurchaseOpportunityRequestActionsProps> = ({
  opportunity,
  userId,
  formChanged,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [entries, setEntries] = useState<AdminPurchaseOpportunityEntryDTO[]>([]);

  const fetchAdminOpportunityEntries = async () => {
    try {
      setLoading(true);
      const res: AdminPurchaseOpportunityEntryDTO[] = await getAdminPurchaseOpportunityEntriesForUser(
        opportunity.purchaseOpportunityId,
        userId
      );
      setEntries(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!opportunity?.purchaseOpportunityId) {
      void fetchAdminOpportunityEntries();
    }
  }, [opportunity]);

  return (
    <div>
      <Title>Admin</Title>
      {loading ? (
        <Loader type="spinner" size="40px" />
      ) : (
        <GamesContainer>
          {entries.map((e: AdminPurchaseOpportunityEntryDTO) => (
            <AdminPurchaseOpportunityGameForm
              key={e.purchaseOpportunityGameId}
              entry={e}
              refresh={fetchAdminOpportunityEntries}
              formChanged={formChanged}
            />
          ))}
        </GamesContainer>
      )}
    </div>
  );
};

export default AdminPurchaseOpportunityRequestActions;
