import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getUser, getUsers } from "../api/RequesTixApi";

export const user = createQueryKeys("user", {
  getUser: (userId: number | string) => ({
    queryKey: [userId],
    queryFn: () => getUser(userId),
  }),
  getUsers: (departmentId: number | string, seasonId: number | string) => ({
    queryKey: [departmentId, seasonId],
    queryFn: () => getUsers(departmentId, seasonId),
  }),
});
