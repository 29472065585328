import {
  Button,
  Card,
  Column,
  Row,
  Search,
  Select,
  StickyFooterButtons,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { updateDepartmentsBySeason } from "../../../api/RequesTixApi";
import BulkEditDepartmentModal from "../../../components/department/BulkEditDepartmentModal";
import DepartmentTable from "../../../components/department/DepartmentTable";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { useMetadata } from "../../../contexts/MetadataContext";
import { DepartmentBySeasonDTO, UpdateDepartmentsBySeasonDTO } from "../../../types/Department";
import { Season } from "../../../types/Season";
import { convertToEventTypeDTO } from "../../../util/CutoffUtil";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { queries } from "../../../queries";

type UpdateDepartmentParams = {
  departmentIds: number[];
  values: Omit<UpdateDepartmentsBySeasonDTO, "departmentIds" | "season">;
};

const AdminDepartments = () => {
  const { loading, setLoadingNonBlocking } = useLoading();
  const { seasonsOptions } = useDropdowns();
  const { activeSeason } = useMetadata();
  const queryClient = useQueryClient();
  const [selectedDepartments, setSelectedDepartments] = useState<DepartmentBySeasonDTO[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedSeason, setSelectedSeason] = useState<Season>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");

  // React Query hook for fetching departments
  const { data: departments = [], isLoading } = useQuery<DepartmentBySeasonDTO[]>({
    ...queries.departments.getDepartmentsBySeason(selectedSeason?.seasonId),
    enabled: !!selectedSeason?.seasonId,
  });

  // React Query mutation for updating departments
  const updateMutation = useMutation({
    mutationFn: ({ departmentIds, values }: UpdateDepartmentParams) =>
      updateDepartmentsBySeason(departmentIds, selectedSeason, values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.departments._def });
      setSelectedDepartments([]);
      setShowEditModal(false);
    },
  });

  const onSave = async ({ personalAllotment, businessAllotment, access, cutoff, personalRequests }) => {
    try {
      setLoadingNonBlocking(true);
      const values = {
        personalAllotment: personalAllotment.amount || personalAllotment.unlimited ? personalAllotment : null,
        businessAllotment: businessAllotment.amount || businessAllotment.unlimited ? businessAllotment : null,
        access: access.active ? access : null,
        cutoff: cutoff.active ? convertToEventTypeDTO(cutoff) : null,
        personalRequests: personalRequests,
      };
      const departmentIds: number[] = selectedDepartments.map((d: DepartmentBySeasonDTO) => d.departmentId);
      await updateMutation.mutateAsync({ departmentIds, values });
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingNonBlocking(false);
    }
  };

  useEffect(() => {
    setSelectedSeason(activeSeason);
  }, [activeSeason]);

  return (
    <Card>
      <Card.Header>Departments</Card.Header>
      <Card.Body>
        {showEditModal && (
          <BulkEditDepartmentModal
            show={showEditModal}
            selected={selectedDepartments}
            season={`${selectedSeason.year} ${selectedSeason.seasonType.seasonTypeName}`}
            onClose={() => setShowEditModal(false)}
            onSave={onSave}
          />
        )}

        <Row>
          <Column width={4}>
            <Select
              id="seasons"
              label="Season"
              options={seasonsOptions}
              value={seasonsOptions.find(
                (option: ValueOpt<Season>) => option.value.seasonId === selectedSeason?.seasonId
              )}
              onChange={(value) => setSelectedSeason(value?.value)}
              gutterBottom
            />
          </Column>
          <Column width={4}>
            <Search
              id="search-box"
              label="Search Departments"
              placeholder="begin typing to filter departments"
              value={searchTerm}
              onChange={setSearchTerm}
              gutterBottom
            />
          </Column>
        </Row>
        <DepartmentTable
          values={departments}
          searchTerm={searchTerm}
          selectedSeason={selectedSeason}
          selected={selectedDepartments}
          setSelected={setSelectedDepartments}
          loading={loading || isLoading}
        />
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="default" disabled={selectedDepartments.length === 0} onClick={() => setShowEditModal(true)}>
            Update Departments
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminDepartments;
