import { Button, Card, StickyFooterButtons, urlReplace, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getPurchaseEntry, getPurchaseOpportunity, updatePurchaseEntry } from "../../api/RequesTixApi";
import AdminPurchaseOpportunityRequestActions from "../../components/purchaseOpportunity/AdminPurchaseOpportunityRequestActions";
import PurchaseOpportunityCard from "../../components/purchaseOpportunity/PurchaseOpportunityCard";
import PurchaseOpportunityForm from "../../components/purchaseOpportunity/PurchaseOpportunityForm";
import RouteConstants from "../../constants/RouteConstants";
import { useAuth } from "../../contexts/AuthContext";
import { PurchaseOpportunityDTO, PurchaseOpportunityEntryRecordDTO } from "../../types/PurchaseOpportunity";
import { useQueryClient } from "@tanstack/react-query";
import { queries } from "../../queries";

const Container = styled.div`
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

type RouteParams = {
  purchaseOpportunityId: string;
  purchaseOpportunityEntryId: string;
};

const PurchaseOpportunityRequestEdit: React.FC = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const queryClient = useQueryClient();
  const { isAdmin } = useAuth();
  const { purchaseOpportunityId, purchaseOpportunityEntryId } = useParams<RouteParams>();
  const [opportunity, setOpportunity] = useState<PurchaseOpportunityDTO | undefined>();
  const [entry, setEntry] = useState<PurchaseOpportunityEntryRecordDTO>({ purchaseOpportunityGameIds: [] });
  const [canSave, setCanSave] = useState<boolean>(false);
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const fetchData = async (purchaseOpportunityId: string, purchaseOpportunityEntryId: string) => {
    try {
      setLoading(true);
      const calls = [
        fetchPurchaseOpportunity(purchaseOpportunityId),
        fetchPurchaseOpportunityEntry(purchaseOpportunityId, purchaseOpportunityEntryId),
      ];
      await Promise.all(calls);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchPurchaseOpportunity = async (id: string) => {
    const res: PurchaseOpportunityDTO = await getPurchaseOpportunity(id);
    setOpportunity(res);
  };

  const fetchPurchaseOpportunityEntry = async (purchaseOpportunityId: string, purchaseOpportunityEntryId: string) => {
    const res: PurchaseOpportunityEntryRecordDTO = await getPurchaseEntry(
      purchaseOpportunityId,
      purchaseOpportunityEntryId
    );
    setEntry(res);
  };

  const saveRequest = async () => {
    try {
      setLoading(true);
      await updatePurchaseEntry(entry);
      await queryClient.invalidateQueries({
        queryKey: queries.purchaseOpportunity._def,
      });
      if (isAdmin) {
        navigate(urlReplace(RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.VIEW, { id: purchaseOpportunityId }));
      } else {
        navigate(RouteConstants.PURCHASE_OPPORTUNITY.REQUESTS);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const cancel = () => {
    if (isAdmin) {
      navigate(-1);
    } else {
      navigate(RouteConstants.PURCHASE_OPPORTUNITY.REQUESTS);
    }
  };

  useEffect(() => {
    if (!!purchaseOpportunityId && purchaseOpportunityEntryId) {
      void fetchData(purchaseOpportunityId, purchaseOpportunityEntryId);
    }
  }, [purchaseOpportunityId, purchaseOpportunityEntryId]);

  return (
    <Card>
      <Card.Header>Purchase Opportunity</Card.Header>
      <Card.Body>
        <div>
          {!!opportunity && (
            <>
              <Container>
                <PurchaseOpportunityCard opp={opportunity} />
                <PurchaseOpportunityForm
                  opportunity={opportunity}
                  entry={entry}
                  setEntry={setEntry}
                  setValidForm={setCanSave}
                  setFormChanged={setFormChanged}
                  newEntry={false}
                  isAdmin={true}
                />
              </Container>
              <div>
                {isAdmin && !!opportunity && !!entry?.user && (
                  <AdminPurchaseOpportunityRequestActions
                    opportunity={opportunity}
                    userId={entry.user.employeeUserId}
                    formChanged={formChanged}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons startsOpen={true}>
          {isAdmin && (
            <Button variant="default" onClick={cancel}>
              Go Back
            </Button>
          )}
          <Button variant="primary" disabled={!canSave} onClick={saveRequest}>
            Update Request
          </Button>
          <Button variant="default" onClick={cancel}>
            Cancel
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default PurchaseOpportunityRequestEdit;
