import { Button, Label } from "best-common-react";
import React, { useState } from "react";
import { StatsApiGame } from "../../../../types/Stats";
import GameSelectorModal from "./GameSelectorModal";

type GameSelectorProps = {
  onChange: (value: number) => void;
  label?: string;
  required?: boolean;
};

const GameSelector: React.FC<GameSelectorProps> = ({ onChange, label, required }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="d-flex flex-column align-items-start">
      <GameSelectorModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setSelectedGame={(game: StatsApiGame) => onChange(game.gamePk)}
      />
      <div className="d-flex align-items-start bcr-mb-2">
        {!!label && (
          <Label htmlFor="game-selector" required={required}>
            {label}
          </Label>
        )}
        <Button variant="link" className="bcr-p-0 bcr-m-0 bcr-ms-2" onClick={() => setIsOpen(true)}>
          select
        </Button>
      </div>
    </div>
  );
};

export default GameSelector;
