import { AttributeHeader, AttributeValue, MobileView, GameInfo } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getEvent } from "../../../api/RequesTixApi";
import CreditCardMappings from "../../../constants/CreditCardMappings";
import { APPROVED } from "../../../constants/RequestTableConstants";
import { GameDTO } from "../../../types/Game";
import { UserTicketRequestDTO } from "../../../types/UserRequest";
import { isChargeCard, isChargeDept } from "../../../util/TicketRequestUtil";
import { formatSubmittedDate } from "../../../util/TimeUtil";
import { CreditCardIcon } from "../../payment/CreditCard";

const AttributeHolder = styled.div.attrs(() => ({
  className: "d-flex flex-column flex-md-row align-items-md-center bcr-mb-0 mb-md-1",
}))``;

const Spacer = styled.div`
  height: 1.5625rem;
`;

const OriginalRequest = ({ gamePk }: { gamePk: number | string }) => {
  const [game, setGame] = useState<GameDTO | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (gamePk) {
      setLoading(true);
      getEvent(gamePk).then((data) => {
        setGame(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [gamePk]);

  return (
    <AttributeHolder>
      {!!gamePk && game?.teams ? (
        <>
          <AttributeHeader>Original Request</AttributeHeader>
          <AttributeValue>
            {!!game && (
              <GameInfo
                gameDate={game.gameDate}
                venue={game.venue}
                home={game.teams.home.team}
                away={game.teams.away.team}
                seriesDescription={game.seriesDescription}
              />
            )}
          </AttributeValue>
        </>
      ) : (
        <>{loading ? <Spacer /> : null}</>
      )}
    </AttributeHolder>
  );
};

type RequestProps = {
  request: UserTicketRequestDTO;
};

const MyRequestDetailsApproved = ({ request }: RequestProps) => (
  <div className="d-flex flex-column flex-md-row">
    <div className="bcr-me-4">
      <MobileView>
        <AttributeHeader>Fulfillment</AttributeHeader>
        <AttributeValue>{request?.fulfillmentType}</AttributeValue>
      </MobileView>

      <AttributeHolder>
        <AttributeHeader>Delivery Email</AttributeHeader>
        <AttributeValue>{request?.deliveryEmail}</AttributeValue>
      </AttributeHolder>

      <AttributeHolder>
        <AttributeHeader>Delivery Method</AttributeHeader>
        <AttributeValue>{request?.deliveryMethod?.deliveryMethod}</AttributeValue>
      </AttributeHolder>

      <AttributeHolder>
        <AttributeHeader>Additional Notes</AttributeHeader>
        <AttributeValue>{request?.additionalNotes}</AttributeValue>
      </AttributeHolder>

      <OriginalRequest gamePk={request?.originalGamePk} />
    </div>
    <div className="bcr-me-4">
      <AttributeHolder>
        <AttributeHeader>Comp Quantity</AttributeHeader>
        <AttributeValue>{request?.compQuantity}</AttributeValue>
      </AttributeHolder>

      {isChargeDept(request) || isChargeCard(request) ? (
        <>
          <AttributeHolder>
            <AttributeHeader>Charge Quantity</AttributeHeader>
            <AttributeValue>{request?.chargeQuantity}</AttributeValue>
          </AttributeHolder>

          <AttributeHolder>
            <AttributeHeader>Total Ticket Cost</AttributeHeader>
            <AttributeValue>{request?.totalTicketCost && <span>${request?.totalTicketCost}</span>}</AttributeValue>
          </AttributeHolder>
        </>
      ) : null}

      {isChargeCard(request) && (
        <AttributeHolder>
          <AttributeHeader>Credit Card</AttributeHeader>
          <AttributeValue>
            {!!request?.userBilling && (
              <div className="d-flex align-items-center">
                <CreditCardIcon type={CreditCardMappings[request?.userBilling.ccType.ccTypeName]} />
                <span className="bcr-ms-2">
                  {request?.userBilling.ccNickname} ending in {request?.userBilling.ccLast4}
                </span>
              </div>
            )}
          </AttributeValue>
        </AttributeHolder>
      )}
      <MobileView>
        <AttributeHolder>
          <AttributeHeader>Submitted</AttributeHeader>
          <AttributeValue>{formatSubmittedDate(request?.submitted)}</AttributeValue>
        </AttributeHolder>
      </MobileView>
    </div>
  </div>
);

const MyRequestDetailsDenied = ({ request }: RequestProps) => (
  <div className="bcr-me-4">
    <AttributeHolder>
      <AttributeHeader>Additional Notes</AttributeHeader>
      <AttributeValue>{request?.additionalNotes}</AttributeValue>
    </AttributeHolder>
  </div>
);

const MyRequestDetails = ({ request }: RequestProps) => (
  <>
    {request?.status.requestStateId === APPROVED ? (
      <MyRequestDetailsApproved request={request} />
    ) : (
      <MyRequestDetailsDenied request={request} />
    )}
  </>
);

export default MyRequestDetails;
