import { Accordion, CustomMobileAccordionType, Icon, Link, MatchUp, useTheme } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { OPEN } from "../../../constants/RequestTableConstants";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { GameTime } from "../../../types/Game";
import { getStatusColor, removeRequestId } from "../../../util/TicketRequestUtil";
import { useTimezone } from "../../../util/TimeUtil";
import MyRequestDetails from "./MyRequestDetails";
import { FlattenedUserTicketRequestDTO } from "./MyRequestsTable";

const Status = styled.div.attrs(() => ({
  className: "bcr-px-3 bcr-py-2 d-flex justify-content-between",
}))`
  height: 2.5625rem;
  background: ${(props) => props.color};
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
`;

const Header = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.palette.onColorsOnSurfaceLow};
`;

const SubDescription = styled.div`
  font-size: 0.875rem;
  color: #666666;
`;

const MyRequestAccordion = ({ data: request, ...rest }: CustomMobileAccordionType<FlattenedUserTicketRequestDTO>) => {
  const { Theme } = useTheme();
  const [gameTime, setGameTime] = useState<GameTime | undefined>(undefined);

  useEffect(() => {
    setGameTime(useTimezone(request.game.gameDate, request.game.venue.timeZone, request.game.status));
  }, [request]);

  return (
    <div>
      <Status color={getStatusColor(Theme, request.requestId, request.status.requestStateId)}>
        <div>{request.status.requestStateName}</div>
        {request.status.requestStateId === OPEN ? (
          <Link
            to={{
              pathname: urlReplace(RouteConstants.REQUESTS.EDIT, { requestId: request.requestId }),
            }}
            state={{ previousLocation: { ...request.location, search: removeRequestId(request.location) } }}
          >
            <Icon iconName="fa-pencil-alt" />
          </Link>
        ) : null}
      </Status>
      <Accordion {...rest}>
        <Accordion.Header>
          <div className="d-flex justify-content-between">
            <div>
              <Header>{gameTime?.date}</Header>
              <Description>
                {gameTime?.day}
                <span className="bcr-ms-2">{gameTime?.time}</span>
              </Description>
            </div>
            <MatchUp
              home={request.game.teams.home.team}
              away={request.game.teams.away.team}
              description={request.game.seriesDescription}
            />
          </div>
          <SubDescription>
            {request.quantity} {request?.category} ticket(s) for {request.recipient}
          </SubDescription>
        </Accordion.Header>
        <Accordion.Body>
          <MyRequestDetails request={request} />
        </Accordion.Body>
      </Accordion>
    </div>
  );
};

export default MyRequestAccordion;
