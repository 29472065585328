import { useLoading } from "best-common-react";
import React, { useEffect, useMemo, useState } from "react";
import { getTodaysDigitalTickets, sendDayOfRequest } from "../../api/RequesTixApi";
import { DayOfRequestDTO, DayOfUnsentTicketDTO } from "../../types/TodaysTickets";
import TicketSelectionModal from "../tickets/TicketSelectionModal";

type DayOfGameRequestProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  gamePk: number;
  teamId: string | number;
  ticketsLeft: number;
  allotment?: number;
  isLotto: boolean;
};

const TodaysTicketsGameRequest: React.FC<DayOfGameRequestProps> = ({
  isOpen,
  onClose,
  onSave,
  gamePk,
  teamId,
  ticketsLeft,
  allotment,
  isLotto,
}) => {
  const { setLoading } = useLoading();
  const [email, setEmail] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [availableTickets, setAvailableTickets] = useState<DayOfUnsentTicketDTO[]>([]);
  const [selectedTickets, setSelectedTickets] = useState<string[]>([]);
  const [dayOfTicketIds, setDayOfTicketIds] = useState<number[]>([]);

  const sendRequest = async () => {
    try {
      setLoading(true);
      const request: DayOfRequestDTO = {
        ticketIds: selectedTickets,
        ballparkEmailAddress: email,
        dayOfTicketIds: dayOfTicketIds,
        teamId: teamId,
        gamePk: gamePk,
      };
      await sendDayOfRequest(request);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      onClose();
      onSave();
    }
  };

  const fetchTickets = async () => {
    try {
      setLoading(true);
      const res: DayOfUnsentTicketDTO[] = await getTodaysDigitalTickets(gamePk, teamId);
      setAvailableTickets(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const setSelectedDayOfTicketValues = (values: string[]) => {
    const matchedTickets = availableTickets.filter((ticket: DayOfUnsentTicketDTO) => values.includes(ticket.ticketID));
    setDayOfTicketIds(matchedTickets.map((ticket: DayOfUnsentTicketDTO) => ticket.dayOfAvailableTicketId));
  };

  const close = () => {
    onClose();
    setEmail("");
  };

  const ticketQuantity = useMemo(() => {
    return Math.min(ticketsLeft, allotment);
  }, [ticketsLeft, allotment]);

  const canSave = (): boolean => {
    return !!validEmail && selectedTickets.length > 0 && selectedTickets.length <= ticketQuantity;
  };

  useEffect(() => {
    if (!!isOpen) {
      fetchTickets();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!!availableTickets.length) {
      setSelectedDayOfTicketValues(selectedTickets);
    }
  }, [availableTickets, selectedTickets]);

  return (
    <>
      {isOpen && availableTickets.length > 0 && (
        <TicketSelectionModal
          isOpen={isOpen}
          close={() => {
            close();
          }}
          tickets={availableTickets}
          ticketQuantity={ticketQuantity}
          selectedTickets={selectedTickets}
          setSelectedTickets={(values) => {
            setSelectedTickets(values);
          }}
          showSimpleColumns={true}
          save={sendRequest}
          refreshTickets={fetchTickets}
          showEmail={true}
          email={email}
          setEmail={setEmail}
          setValidEmail={setValidEmail}
          canSave={canSave}
          todaysTickets={true}
          primaryButtonText={isLotto ? "Enter Lottery" : "Send Tickets"}
          ticketQuantityText={`Please select up to ${ticketQuantity} tickets`}
        />
      )}
    </>
  );
};

export default TodaysTicketsGameRequest;
