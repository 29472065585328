import { Button, Modal, useLoading } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { deleteTicketRequest } from "../../../api/RequesTixApi";

const BodyText = styled.div`
  font-weight: 300;
  font-size: 1.125rem;
`;

const BodySubtext = styled.div`
  font-weight: 300;
  font-size: 0.875rem;
  color: #666666;
  margin-top: 0.75rem;
`;

const DeleteModal = ({ show, onDelete, onClose }) => (
  <Modal show={show} size="lg">
    <Modal.Header close={onClose}>Delete Request</Modal.Header>
    <Modal.Body>
      <BodyText>Are you sure you want to delete the request?</BodyText>
      <BodySubtext>This is a permanent action and cannot be undone.</BodySubtext>
    </Modal.Body>
    <Modal.Footer>
      <div>
        <Button variant="primary" className="bcr-me-2" onClick={onDelete}>
          Delete
        </Button>
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal.Footer>
  </Modal>
);

const DeleteTicketRequest = ({ requestId, goBack }) => {
  const { setLoading } = useLoading();
  const [showModal, setShowModal] = React.useState(false);

  const onDelete = () => {
    setLoading(true);
    deleteTicketRequest(requestId).then(() => {
      setLoading(false);
      goBack();
    });
  };

  return (
    <>
      <DeleteModal show={showModal} onDelete={onDelete} onClose={() => setShowModal(false)} />
      <Button variant="default" onClick={() => setShowModal(true)}>
        Delete
      </Button>
    </>
  );
};

export default DeleteTicketRequest;
