import { Checkbox, DesktopTabletView, Input, MobileView, Table } from "best-common-react";
import React from "react";
import styled from "styled-components";
import UserAllotmentConstants from "../../constants/UserAllotmentConstants";

const AllotmentInput = styled.div`
  margin-right: 1rem;
  width: 3rem;
`;

type EditableAllotmentProps = {
  id: string;
  type: string;
  value: number;
  unlimited: boolean;
  onChange: (key: string, type: string, value: number | boolean) => void;
};

const EditableAllotment = ({ id, type, value, unlimited, onChange }: EditableAllotmentProps) => (
  <Table.Td>
    <div className="d-flex align-items-center">
      {!unlimited && (
        <AllotmentInput>
          <Input
            id={`unlimited-${id}`}
            type="number"
            disabled={unlimited}
            value={value}
            onChange={(value) => {
              onChange("amount", type, value);
            }}
          />
        </AllotmentInput>
      )}
      <Checkbox
        id={`checkbox-${id}`}
        label="unlimited"
        checked={unlimited}
        onChange={() => {
          onChange("unlimited", type, !unlimited);
        }}
      />
    </div>
  </Table.Td>
);

const CompedDisplay = ({ value }) => <span className="bcr-ms-2">{value > 0 ? `(${value} comped)` : ""}</span>;

type UserAllotmentDisplayProps = {
  season: string;
  allotments: any;
  displayUnlimited?: boolean;
  editable?: boolean;
  onChange: (key: string, type: string, value: number | boolean) => void;
  loading?: boolean;
};

const UserAllotmentDisplay = ({
  season,
  allotments = {},
  displayUnlimited = true,
  editable = false,
  onChange = () => {},
}: UserAllotmentDisplayProps) => (
  <>
    {allotments?.personal && allotments?.business && (
      <>
        <div className="bcr-mt-3">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header style={{ width: "30%" }}>{season}</Table.Header>
                <Table.Header>Personal</Table.Header>
                <DesktopTabletView>
                  <Table.Header>Business</Table.Header>
                </DesktopTabletView>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.TdInfo>Pending</Table.TdInfo>
                <Table.Td>{allotments.personal.pending}</Table.Td>
                <DesktopTabletView>
                  <Table.Td>{allotments.business.pending}</Table.Td>
                </DesktopTabletView>
              </Table.Row>
              <Table.Row>
                <Table.TdInfo>Approved</Table.TdInfo>
                <Table.Td>
                  {allotments.personal.used}
                  <CompedDisplay value={allotments.personal.comped} />
                </Table.Td>
                <DesktopTabletView>
                  <Table.Td>
                    {allotments.business.used} <CompedDisplay value={allotments.business.comped} />
                  </Table.Td>
                </DesktopTabletView>
              </Table.Row>
              <Table.Row>
                <Table.TdInfo>Allotted</Table.TdInfo>

                {editable ? (
                  <>
                    <EditableAllotment
                      id={allotments.personal.userAllotmentId + UserAllotmentConstants.PERSONAL + season}
                      type={UserAllotmentConstants.PERSONAL}
                      value={allotments.personal.allowed}
                      unlimited={allotments.personal.unlimited}
                      onChange={onChange}
                    />
                    <DesktopTabletView>
                      <EditableAllotment
                        id={allotments.business.userAllotmentId + UserAllotmentConstants.BUSINESS + season}
                        type={UserAllotmentConstants.BUSINESS}
                        value={allotments.business.allowed}
                        unlimited={allotments.business.unlimited}
                        onChange={onChange}
                      />
                    </DesktopTabletView>
                  </>
                ) : (
                  <>
                    <Table.Td>
                      {allotments.personal.unlimited
                        ? displayUnlimited
                          ? UserAllotmentConstants.UNLIMITED
                          : ""
                        : allotments.personal.allowed}
                    </Table.Td>
                    <DesktopTabletView>
                      <Table.Td>
                        {allotments.business.unlimited
                          ? displayUnlimited
                            ? UserAllotmentConstants.UNLIMITED
                            : ""
                          : allotments.business.allowed}
                      </Table.Td>
                    </DesktopTabletView>
                  </>
                )}
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <MobileView>
          <div className="bcr-mt-3">
            <Table className="bcr-mt-3">
              <Table.Head>
                <Table.Row>
                  <Table.Header style={{ width: "30%" }}>{season}</Table.Header>
                  <Table.Header>Business</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.TdInfo>Pending</Table.TdInfo>
                  <Table.Td>{allotments.business.pending}</Table.Td>
                </Table.Row>
                <Table.Row>
                  <Table.TdInfo>Approved</Table.TdInfo>
                  <Table.Td>
                    {allotments.business.used}
                    <CompedDisplay value={allotments.business.comped} />
                  </Table.Td>
                </Table.Row>
                <Table.Row>
                  <Table.TdInfo>Allotted</Table.TdInfo>
                  {editable ? (
                    <>
                      <EditableAllotment
                        id={allotments.personal.userAllotmentId + UserAllotmentConstants.PERSONAL + season}
                        type={UserAllotmentConstants.PERSONAL}
                        value={allotments.personal.allowed}
                        unlimited={allotments.personal.unlimited}
                        onChange={onChange}
                      />
                    </>
                  ) : (
                    <Table.Td>
                      {allotments.business.unlimited
                        ? displayUnlimited
                          ? UserAllotmentConstants.UNLIMITED
                          : ""
                        : allotments.business.allowed}
                    </Table.Td>
                  )}
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </MobileView>
      </>
    )}
  </>
);

export default UserAllotmentDisplay;
