import { Button, Checkbox, CurrencyInput, Column, Input, Label } from "best-common-react";
import React from "react";
import { AdminSuiteRequestStateDTO } from "../../../types/SuiteRequest";
import GameDisplay from "../form/gameSelector/GameDisplay";
import GameSelector from "../form/gameSelector/GameSelector";
import AdminSuiteConfirmationEmail from "./AdminSuiteConfirmationEmail";
import AdminSuiteDigitalTickets from "./AdminSuiteDigitalTickets";
import { AdminSuiteActionsProps } from "./AdminSuiteRequestsActions";

const AdminSuiteApprovedAction = (props: AdminSuiteActionsProps) => {
  const { request, actionState, setActionState } = props;

  const changeState = (key: keyof AdminSuiteRequestStateDTO, value: number | string | boolean) => {
    setActionState({
      ...actionState,
      [key]: value,
    });
  };

  return (
    <>
      <Column width={2}>
        <div className="d-flex justify-content-between">
          <Label htmlFor="game">Game</Label>
          <GameSelector onChange={(value: number) => changeState("selectedGamePk", value)} />
          {!!request.preferredGamePk && (
            <Button
              variant="link"
              className="bcr-p-0 bcr-m-0 bcr-ms-2 bcr-mb-2"
              onClick={() => {
                changeState("selectedGamePk", request.preferredGamePk);
              }}
            >
              preferred
            </Button>
          )}
          {!!request.secondaryGamePk && (
            <Button
              variant="link"
              className="bcr-p-0 bcr-m-0 bcr-ms-2 bcr-mb-2"
              onClick={() => {
                changeState("selectedGamePk", request.secondaryGamePk);
              }}
            >
              secondary
            </Button>
          )}
        </div>
        <GameDisplay gamePk={actionState.selectedGamePk} />
      </Column>
      <Column width={2}>
        <Input
          id="amount-of-tickets"
          label="Amount of Tickets"
          type="number"
          value={actionState.numberOfTickets}
          onChange={(value: number) => changeState("numberOfTickets", value)}
          gutterBottom
        />
      </Column>
      <Column width={2}>
        <Input
          id="suite-location"
          label="Suite Location"
          value={actionState.suiteLocation}
          onChange={(value: string) => changeState("suiteLocation", value)}
          gutterBottom
        />
      </Column>
      <Column width={2}>
        <CurrencyInput
          id="total-ticket-cost"
          label="Total Ticket Cost"
          value={actionState.totalTicketCost}
          onChange={(value: number) => changeState("totalTicketCost", value)}
          gutterBottom
        />
      </Column>
      <Column width={2}>
        <CurrencyInput
          id="total-fb-cost"
          label="Total F&B Cost"
          value={actionState.totalFbCost}
          onChange={(value: number) => changeState("totalFbCost", value)}
          gutterBottom
        />
      </Column>
      <Column width={2}>
        <AdminSuiteDigitalTickets actionState={actionState} request={request} {...props} />
      </Column>
      <Column width={2}>
        <div>
          <Checkbox
            id="tickets-delivered"
            label="Tickets Delivered"
            checked={actionState.ticketsDelivered}
            onChange={(value: boolean) => changeState("ticketsDelivered", value)}
            gutterBottom
          />
          {/*<FileAttachment*/}
          {/*  id="manual-file"*/}
          {/*  placeholder="drop tickets here"*/}
          {/*  onChange={(files: File[]) => console.log(files)}*/}
          {/*  maxFiles={1}*/}
          {/*  gutterBottom*/}
          {/*  browseButtonText="Browse"*/}
          {/*  files={[]}*/}
          {/*/>*/}
        </div>
      </Column>
      <AdminSuiteConfirmationEmail {...props} />
    </>
  );
};

export default AdminSuiteApprovedAction;
