import {
  Typography,
  MatchUp,
  StatsTeams,
  StatsAtBatPromotion,
  StatsVenue,
  StatsGameStatus,
  createCssVariableString,
} from "best-common-react";
import React from "react";
import styled from "styled-components";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { EventTimingDTO, EventTimingTypeDTO } from "../../../types/EventTiming";
import { GameRequestCountDTO } from "../../../types/Game";
import { useTimezone } from "../../../util/TimeUtil";
import Promotions from "./Promotions";
import TicketRequestSection from "./TicketRequestSection";
import { useLocation, useNavigate } from "react-router-dom";

type GameCardProps = {
  isPromotion: boolean;
};

const MatchhupContainer = styled.div`
  display: flex;
  align-items: start;
  width: 200px;
`;

const VenueInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 250px;
  margin-end: 1rem;
`;

const GameCard = styled.div<GameCardProps>`
  width: 100%;
  color: ${createCssVariableString("onColorsOnSurfaceLow")};
  border: 1px solid ${createCssVariableString("outlineColorsOutlineVariant")};
  ${(props) => props.isPromotion && "border-bottom: 0;"}
  background-color: ${createCssVariableString("surfaceColorsSurfaceContainerLowest")};
  margin: 0.5rem 0 0 0;
  padding: 0.75rem 1rem;
  ${(props) => props.theme.globals.shadows}
`;

const Description = styled.div`
  width: 100px;
  font-size: 0.875rem;
  color: ${createCssVariableString("onColorsOnSurfaceLow")};
`;

const DescHeader = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${createCssVariableString("onColorsOnSurfaceLow")};
`;

const Header = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  color: ${createCssVariableString("onColorsOnSurfaceLow")};
`;

type GameProps = {
  gamePk: number;
  gameDate: string;
  venue: StatsVenue;
  description: string;
  teams: StatsTeams;
  access: EventTimingTypeDTO;
  cutoff: EventTimingDTO;
  personalRequests: boolean;
  blackedOut: boolean;
  requestCount: GameRequestCountDTO;
  status: StatsGameStatus;
  promotions: StatsAtBatPromotion[];
};

const Game = ({
  gamePk,
  gameDate,
  venue,
  teams,
  access,
  cutoff,
  promotions = [],
  personalRequests,
  requestCount,
  description,
  blackedOut,
  status,
}: GameProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { time, day, date } = useTimezone(gameDate, venue.timeZone, status);

  const toRequest = (type: string) => {
    navigate(urlReplace(RouteConstants.REQUESTS.TICKETS, { gamePk, type }), {
      state: { previousLocation: location },
    });
  };

  return (
    <div>
      <GameCard isPromotion={!!promotions}>
        <div className="d-flex justify-content-between flex-wrap">
          <div className="d-flex justify-content-between flex-grow-1 flex-wrap">
            <MatchhupContainer>
              <div className="bcr-me-2">
                <Header>{date}</Header>
                <Description>
                  <Typography>{day}</Typography>
                </Description>
              </div>
              <MatchUp home={teams.home.team} away={teams.away.team} description={description} />
            </MatchhupContainer>

            <div className="d-flex justify-content-around flex-grow-1">
              <Description>{time}</Description>

              <VenueInfo>
                <DescHeader>{venue.name}</DescHeader>
                {venue.location ? (
                  <Description>
                    {venue.location.city}
                    {venue.location.city && venue.location.stateAbbrev ? ", " : ""}
                    {venue.location.stateAbbrev}
                  </Description>
                ) : null}
              </VenueInfo>
            </div>
          </div>
          <TicketRequestSection
            gameDate={gameDate}
            access={access}
            cutoff={cutoff}
            toRequest={toRequest}
            personalAllowed={personalRequests}
            requestCount={requestCount}
            gamePk={gamePk}
            blackedOut={blackedOut}
          />
        </div>
      </GameCard>
      <Promotions gamePk={gamePk} promotions={promotions} />
    </div>
  );
};

export default Game;
