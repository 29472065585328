import {
  Button,
  Card,
  createBcrSpacing,
  Filters,
  isAfter,
  Search,
  Select,
  StickyFooterButtons,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { exportPurchaseOpportunity, runPurchaseSelection, sendPurchaseEmails } from "../../../api/RequesTixApi";
import AdminPurchaseOpportunityGame from "../../../components/purchaseOpportunity/AdminPurchaseOpportunityGame";
import PurchaseOpportunityCard from "../../../components/purchaseOpportunity/PurchaseOpportunityCard";
import RouteConstants from "../../../constants/RouteConstants";
import {
  AdminPurchaseOpportunityDTO,
  PurchaseOpportunityFilters,
  PurchaseOpportunityGameDTO,
  PurchaseOpportunityStatus,
  PurchaseOpportunityStatusId,
} from "../../../types/PurchaseOpportunity";
import { downloadLink, FileTypes } from "../../../util/FileUtil";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { queries } from "../../../queries";

const Container = styled.div`
  display: flex;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const Games = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-grow: 1;
    margin-left: ${createBcrSpacing(3)};
  }

  @media (max-width: 767px) {
    margin-top: ${createBcrSpacing(3)};
  }
`;

type RouteParams = {
  id: string;
};

const AdminPurchaseOpportunityView = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { id } = useParams<RouteParams>();
  const [allGamesProcessed, setAllGamesProcessed] = useState<boolean>(false);
  const [pastCutoff, setPastCutoff] = useState<boolean>(false);
  const [filters, setFilters] = useState<PurchaseOpportunityFilters>({});
  const { purchaseOpportunityStatusOptions } = useDropdowns();
  const queryClient = useQueryClient();

  const goBack = () => {
    navigate(RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.BASE);
  };

  const onFiltersChange = (key: keyof PurchaseOpportunityFilters, value: string | PurchaseOpportunityStatusId[]) => {
    setFilters({ ...filters, [key]: value });
  };

  const invalidateQueryKey = async () => {
    await queryClient.invalidateQueries({
      queryKey: queries.purchaseOpportunity.getAdminPurchaseOpportunity(parseInt(id)).queryKey,
    });
  };

  // Query for fetching the purchase opportunity
  const {
    data: opp,
    isLoading,
    refetch,
  } = useQuery<AdminPurchaseOpportunityDTO>({
    ...queries.purchaseOpportunity.getAdminPurchaseOpportunity(parseInt(id)),
  });

  // Mutation for running selection
  const runSelectionMutation = useMutation({
    mutationFn: () => runPurchaseSelection(opp.purchaseOpportunityId),
    onSuccess: async () => {
      await invalidateQueryKey();
    },
  });

  // Mutation for sending emails
  const sendEmailsMutation = useMutation({
    mutationFn: () => sendPurchaseEmails(opp.purchaseOpportunityId),
    onSuccess: async () => {
      await invalidateQueryKey();
    },
  });

  const exportData = async () => {
    try {
      setLoading(true);
      const response = await exportPurchaseOpportunity(opp.purchaseOpportunityId);
      downloadLink(response, FileTypes.EXCEL);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const runSelection = () => {
    runSelectionMutation.mutate();
  };

  const sendEmails = () => {
    sendEmailsMutation.mutate();
  };

  const reload = async () => {
    invalidateQueryKey();
    refetch();
  };

  useEffect(() => {
    setAllGamesProcessed(
      !!opp && !opp.purchaseOpportunityGames.map((g: PurchaseOpportunityGameDTO) => g.processed).includes(false)
    );
    setPastCutoff(!!opp && isAfter(new Date(), new Date(opp.cutoffDate as string)));
  }, [opp]);

  return (
    <Card>
      <Card.Header>Purchase Opportunity</Card.Header>
      <Card.Body>
        <Filters>
          <Search
            id="freeText"
            label="Search"
            placeholder="Search by user name or email address"
            value={filters.freeText}
            onChange={(value: string) => onFiltersChange("freeText", value)}
            gutterBottom
          />
          <Select
            id="statusIds"
            label="Status"
            isMulti
            value={purchaseOpportunityStatusOptions.filter((opt: ValueOpt<PurchaseOpportunityStatus>) =>
              filters.statusIds?.includes(opt.value.purchaseOpportunityStatusId)
            )}
            onChange={(values: ValueOpt<PurchaseOpportunityStatus>[]) =>
              onFiltersChange(
                "statusIds",
                values.map((v: ValueOpt<PurchaseOpportunityStatus>) => v.value.purchaseOpportunityStatusId)
              )
            }
            options={purchaseOpportunityStatusOptions}
            gutterBottom
          />
        </Filters>
        <Container>
          {!!opp && (
            <>
              <PurchaseOpportunityCard opp={opp} />
              <Games>
                {opp.purchaseOpportunityGames.map((game: PurchaseOpportunityGameDTO) => (
                  <AdminPurchaseOpportunityGame
                    opp={opp}
                    key={game.purchaseOpportunityGameId}
                    game={game}
                    filters={filters}
                    reload={reload}
                  />
                ))}
              </Games>
            </>
          )}
        </Container>
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="default" onClick={goBack}>
            Go Back
          </Button>
          <Button
            variant="primary"
            disabled={allGamesProcessed || !pastCutoff || runSelectionMutation.isPending || isLoading}
            onClick={runSelection}
          >
            Run Selection
          </Button>
          <Button
            variant="primary"
            disabled={!allGamesProcessed || opp?.emailsSent || sendEmailsMutation.isPending || isLoading}
            onClick={sendEmails}
          >
            Send Emails
          </Button>
          <Button variant="secondary" onClick={exportData} disabled={!opp?.purchaseOpportunityId || isLoading}>
            Export
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminPurchaseOpportunityView;
