import {
  addWeeks,
  Card,
  DateRange,
  DateRangeInput,
  DATE_FORMATTER_STR,
  formatDate,
  Column,
  NotApplicable,
  Row,
  Select,
  isBefore,
  ScrollContainer,
  Loader,
  ValueOpt,
  StatsTeam,
} from "best-common-react";
import React, { useEffect, useMemo, useState } from "react";
import Game from "../components/requests/card/Game";
import SeasonSelect from "../components/requests/card/SeasonSelect";
import RouteConstants from "../constants/RouteConstants";
import { useAuth } from "../contexts/AuthContext";
import { useDropdowns } from "../contexts/DropdownsContext";
import { useMetadata } from "../contexts/MetadataContext";
import { UserAllotmentCountDTO } from "../types/Allotment";
import { GameDTO, GameRequestCountMap } from "../types/Game";
import { UserDTO } from "../types/User";
import { updateRouteParams, useRouteParams } from "../util/RouteUtil";
import { seasonTypesCodes, SPRING_TRAINING } from "../util/SeasonUtil";
import { getTeamOption } from "../util/TeamUtil";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { queries } from "../queries";

const today = new Date();
const nextWeek = addWeeks(today, 1);
const todayStr = formatDate(today, DATE_FORMATTER_STR);
const nextWeekStr = formatDate(nextWeek, DATE_FORMATTER_STR);

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo } = useAuth();
  const { teamsOptions } = useDropdowns();
  const { activeSeason } = useMetadata();
  const [_allotments, setAllotments] = useState<UserAllotmentCountDTO[]>([]);
  const [selectedSeason, setSelectedSeason] = useState(seasonTypesCodes[SPRING_TRAINING]);

  const { startDate = todayStr, endDate = nextWeekStr, homeTeamId, opponentTeamId } = useRouteParams(location.search);

  const dateRange: DateRange<Date> = {
    start: new Date(Date.parse(startDate)),
    end: new Date(Date.parse(endDate)),
  };

  const selectedHomeTeam = useMemo(() => getTeamOption(homeTeamId, teamsOptions), [homeTeamId, teamsOptions]);
  const selectedOpponent = useMemo(() => getTeamOption(opponentTeamId, teamsOptions), [opponentTeamId, teamsOptions]);
  const hasDateLimit: boolean = useMemo(() => !opponentTeamId && !homeTeamId, [opponentTeamId, homeTeamId]);

  const updateUrl = ({ startDate, endDate, homeTeamId, opponentTeamId }) => {
    updateRouteParams(navigate, RouteConstants.BASE, {
      startDate: startDate,
      endDate: endDate,
      homeTeamId: homeTeamId,
      opponentTeamId: opponentTeamId,
    });
  };

  const updateDate = (dateRange: DateRange<Date>) => {
    updateUrl({
      startDate: formatDate(dateRange.start, DATE_FORMATTER_STR),
      endDate: formatDate(dateRange.end, DATE_FORMATTER_STR),
      homeTeamId,
      opponentTeamId,
    });
  };

  const { data: user } = useQuery<UserDTO>({
    ...queries.user.getUser(userInfo.employeeUserId),
    enabled: !!userInfo.employeeUserId,
  });

  const { data: schedule = [], isLoading: isScheduleLoading } = useQuery<GameDTO[]>({
    ...queries.schedule.getSchedule(startDate, endDate, homeTeamId, opponentTeamId),
    enabled: isBefore(startDate, endDate),
  });

  const { data: gameRequestCounts = {} } = useQuery<GameRequestCountMap>({
    ...queries.gameRequestCounts.getGameRequestCounts(schedule.map((game) => game.gamePk)),
    enabled: schedule.length > 0,
  });

  useEffect(() => {
    if (activeSeason.seasonType) {
      setSelectedSeason(seasonTypesCodes[activeSeason.seasonType.seasonTypeName]);
    }
  }, [activeSeason]);

  return (
    <Card>
      <Card.Header>Request Tickets</Card.Header>
      <Card.Body>
        <Row className="bcr-mt-2">
          <Column width={4}>
            <Select
              id="hometeam-dropdown"
              label="Home Team"
              options={teamsOptions}
              value={selectedHomeTeam}
              onChange={(team: ValueOpt<StatsTeam>) =>
                updateUrl({ startDate, endDate, homeTeamId: team.value.id, opponentTeamId })
              }
            />
          </Column>
          <Column width={4}>
            <Select
              id="opponent-dropdown"
              label="Opponent"
              options={teamsOptions}
              value={selectedOpponent}
              onChange={(team: ValueOpt<StatsTeam>) =>
                updateUrl({ startDate, endDate, homeTeamId, opponentTeamId: team.value.id })
              }
            />
          </Column>
          <Column width={4}>
            <DateRangeInput
              id="date-range"
              label="Date"
              value={dateRange}
              onChange={(value: DateRange<any> | NotApplicable) => {
                updateDate(value as DateRange<Date>);
              }}
              maxRange={
                hasDateLimit
                  ? {
                      weeks: 1,
                    }
                  : undefined
              }
              withPortal
            />
          </Column>
          <Column width={4}>
            <SeasonSelect
              userId={user?.employeeUserId}
              year={activeSeason.year?.toString()}
              seasonType={selectedSeason}
              setSeasonType={setSelectedSeason}
              setAllotments={setAllotments}
              pendingLink={true}
            />
          </Column>
        </Row>
        {isScheduleLoading ? (
          <div className="bcr-mt-5 d-flex justify-content-center align-items-center w-100 h-100">
            <Loader type="spinner" />
          </div>
        ) : (
          <ScrollContainer offset={24} className="bcr-mt-2">
            {schedule?.map((game: GameDTO) => (
              <Game
                key={game.gamePk}
                gamePk={game.gamePk}
                gameDate={game.gameDate}
                venue={game.venue}
                description={game.seriesDescription}
                teams={game.teams}
                access={game.access}
                cutoff={game.cutoff}
                personalRequests={game.personalRequests}
                blackedOut={game.blackedOut}
                requestCount={gameRequestCounts[game.gamePk]}
                status={game.status}
                promotions={game.atBatPromotions}
              />
            ))}
          </ScrollContainer>
        )}
      </Card.Body>
    </Card>
  );
};

export default Home;
