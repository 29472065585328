import { Attribute, Icon, MobileView, useBreakpoints } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { ProjectUserTicketRequestDTO } from "./ProjectRequestsReport";
import { formatSubmittedDate } from "../../../util/TimeUtil";
import clsx from "clsx";
const CommentText = styled.div.attrs(() => ({
  className: "bcr-ms-2",
}))`
  font-size: 0.875rem;
`;

const ProjectRequestDetails = ({ value }: { value: ProjectUserTicketRequestDTO }) => {
  const { isMobile } = useBreakpoints();
  return (
    <div className={clsx("d-flex flex-column")}>
      <div className={clsx("d-flex", { "flex-column": isMobile, "flex-row": !isMobile })}>
        <MobileView>
          <Attribute header="Requester" value={value.requester} className="bcr-me-3" />
          <Attribute header="Fulfillment" value={value.fulfillmentType} className="bcr-me-3" />
          <Attribute
            header="Total Ticket Cost"
            value={value.totalTicketCost ? `$${value.totalTicketCost}` : ""}
            className="bcr-me-3"
          />
          <Attribute header="Home Team" value={value.homeTeam} className="bcr-me-3" />
        </MobileView>
        <Attribute header="Recipient" value={value.recipient} className="bcr-me-3" />
        <Attribute header="Supervisor" value={value.supervisor} className="bcr-me-3" />
        <Attribute header="Submitter" value={value.submitter} className="bcr-me-3" />
        <Attribute header="Submitted" value={formatSubmittedDate(value.submitted)} className="bcr-me-3" />
      </div>
      {value.comments && (
        <div>
          <div className="bcr-ms-0 ms-md-4 d-flex align-items-center">
            <Icon iconName="fa-comment" />
            <CommentText>{value.comments}</CommentText>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectRequestDetails;
