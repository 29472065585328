import {
  Accordion,
  ActiveFormatter,
  addWeeks,
  Attribute,
  Card,
  CustomMobileAccordionType,
  DataTable,
  DataTableColumn,
  DateRange,
  DateRangeInput,
  DateTimeFormatter,
  DATE_FORMATTER_STR,
  DATE_FORMAT_STR,
  formatDate,
  FormatterType,
  Column,
  getRawDate,
  IconLinkFormatter,
  Row,
  Select,
  SortFilters,
  StatsGameFormatter,
  tableSort,
  TextFormatter,
  useLoading,
  ValueOpt,
  StatsTeam,
  GameInfo,
  StickyFooterButtons,
  Button,
} from "best-common-react";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { exportDigitalTicketsReport, getDigitalTicketsRecords } from "../../../api/RequesTixApi";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { DigitalTicketDeliveryDTO, DigitalTicketType } from "../../../types/DigitalTickets";
import { updateRouteParams, useRouteParams } from "../../../util/RouteUtil";
import { downloadLink, FileTypes } from "../../../util/FileUtil";

const DigitalTicketDeliveryAccordion: React.FC<CustomMobileAccordionType<DigitalTicketDeliveryDTO>> = ({
  data,
  ...rest
}) => {
  const {
    requestId,
    game,
    to,
    status,
    active,
    seats,
    from,
    createdTs,
    ticketIds,
    failedTicketIds,
    errorMessage,
    forwardId,
    revokeId,
  } = data;

  return (
    <Accordion {...rest}>
      <Accordion.Header>
        <RequestLinkFormatter value={requestId} row={data} />
        <GameInfo
          gameDate={game.gameDate}
          venue={game.venue}
          home={game.teams.home.team}
          away={game.teams.away.team}
          seriesDescription={game.seriesDescription}
        />
      </Accordion.Header>
      <Accordion.Body>
        <div>
          <Attribute header="To" value={to} />
          <Attribute header="Status" value={status} />
          <Attribute header="Active" value={<ActiveFormatter value={active} />} />
          <Attribute header="Seats" value={seats} />
          <Attribute header="From" value={from} />
          <Attribute header="Created" value={<DateTimeFormatter value={createdTs} />} />
          <Attribute header="Ticket IDs" value={<TicketIdsFormatter value={ticketIds} />} />
          <Attribute header="Failed Ticket IDs" value={<TicketIdsFormatter value={failedTicketIds} />} />
          <Attribute header="Error Msg" value={errorMessage} />
          <Attribute header="Forward ID" value={forwardId} />
          <Attribute header="Revoke ID" value={revokeId} />
        </div>
      </Accordion.Body>
    </Accordion>
  );
};

const RequestLinkFormatter: React.FC<FormatterType<DigitalTicketDeliveryDTO>> = ({ row, ...rest }) => {
  const { requestId, suiteRequestId, todaysTicketRequestId, type } = row;
  let editLink;
  if (type === "Suite Request") {
    editLink = urlReplace(RouteConstants.ADMIN.SUITE_REQUESTS.EDIT, { suiteRequestId });
  } else if (type === "Ticket Request") {
    editLink = urlReplace(RouteConstants.ADMIN.REQUESTS_EDIT, { requestId });
  } else if (type === "Today's Ticket") {
    editLink = urlReplace(RouteConstants.ADMIN.TODAYS_TICKETS_EDIT, { requestId: todaysTicketRequestId });
  }

  if (!!editLink) {
    return <IconLinkFormatter {...rest} value={editLink} icon="fa-pencil-alt" />;
  } else {
    return <div />;
  }
};

const TicketIdsFormatter: React.FC<FormatterType<DigitalTicketDeliveryDTO>> = ({ value = [], ...rest }) => {
  return <TextFormatter {...rest} value={value?.join(", ")} />;
};

const Cols: DataTableColumn<DigitalTicketDeliveryDTO>[] = [
  { key: "requestId", name: "", width: 40, readonlyFormatter: RequestLinkFormatter, fixed: true },
  { key: "type", name: "Type", width: 150, sortable: true, fixed: true },
  {
    name: "Game",
    key: "game",
    readonlyFormatter: StatsGameFormatter,
    width: 325,
    fixed: true,
  },
  { key: "to", name: "To", width: 250, sortable: true },
  { key: "status", name: "Status", width: 100, sortable: true },
  { key: "active", name: "Active", width: 70, readonlyFormatter: ActiveFormatter, cellClass: "justify-content-center" },
  { key: "seats", name: "Seats", width: 250 },
  { key: "from", name: "From", width: 250, sortable: true },
  { key: "createdTs", name: "Sent Date", width: 250, readonlyFormatter: DateTimeFormatter },
  { key: "ticketIds", name: "Ticket IDs", minWidth: 300, readonlyFormatter: TicketIdsFormatter },
  { key: "failedTicketIds", name: "Failed Ticket IDs", width: 300, readonlyFormatter: TicketIdsFormatter },
  { key: "errorMessage", name: "Error Message", width: 100 },
  { key: "forwardId", name: "Forward ID", width: 350 },
  { key: "revokeId", name: "Revoke ID", width: 350 },
];

const today = new Date();
const previousWeek = addWeeks(today, -1);
const todayStr = formatDate(today, DATE_FORMATTER_STR);
const oneWeekAgoStr = formatDate(previousWeek, DATE_FORMATTER_STR);

const AdminDigitalTickets: React.FC = () => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, setLoadingNonBlocking } = useLoading();
  const { teamsOptions, digitalTicketTypeOptions } = useDropdowns();
  const [tickets, setTickets] = useState<DigitalTicketDeliveryDTO[]>([]);
  const [displayTickets, setDisplayTickets] = useState<DigitalTicketDeliveryDTO[]>([]);
  const [sortFilters, setSortFilters] = useState<SortFilters<DigitalTicketDeliveryDTO>>({
    key: "createdTs",
    direction: "DESC",
  });

  const { startDate = oneWeekAgoStr, endDate = todayStr, teamId, type } = useRouteParams(location.search);

  const dateRange: DateRange<Date> = {
    start: new Date(Date.parse(startDate)),
    end: new Date(Date.parse(endDate)),
  };

  const updateUrl = ({
    startDate,
    endDate,
    teamId,
    type,
  }: {
    startDate: Date;
    endDate: Date;
    teamId?: string | number;
    type?: string;
  }) => {
    updateRouteParams(navigate, RouteConstants.ADMIN.DIGITAL_TICKETS, {
      startDate: formatDate(startDate, DATE_FORMATTER_STR),
      endDate: formatDate(endDate, DATE_FORMATTER_STR),
      teamId: teamId,
      type: type,
    });
  };

  const updateDate = useCallback(
    ({ start, end }: DateRange<Date>) => {
      updateUrl({ startDate: start, endDate: end, teamId, type });
    },
    [teamId, type]
  );

  const updateType = useCallback(
    (type: string | undefined) => {
      updateUrl({
        startDate: getRawDate(startDate, DATE_FORMAT_STR),
        endDate: getRawDate(endDate, DATE_FORMAT_STR),
        teamId,
        type: type,
      });
    },
    [startDate, endDate, teamId]
  );

  const updateTeamId = useCallback(
    (teamId: number | string | undefined) => {
      updateUrl({
        startDate: getRawDate(startDate, DATE_FORMAT_STR),
        endDate: getRawDate(endDate, DATE_FORMAT_STR),
        teamId,
        type,
      });
    },
    [startDate, endDate, type]
  );

  const fetchTickets = async (start: string, end: string, teamId?: number, type?: string) => {
    try {
      setLoadingNonBlocking(true);
      const result: DigitalTicketDeliveryDTO[] = await getDigitalTicketsRecords(start, end, teamId, type);
      setTickets(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingNonBlocking(false);
    }
  };

  const exportReport = async () => {
    try {
      setLoading(true);
      const response = await exportDigitalTicketsReport(startDate, endDate, teamId, type);
      downloadLink(response, FileTypes.EXCEL);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchTickets(startDate, endDate, teamId, type);
  }, [startDate, endDate, teamId, type]);

  useEffect(() => {
    setDisplayTickets(tableSort(tickets, sortFilters));
  }, [tickets, sortFilters]);

  return (
    <Card>
      <Card.Header>Digital Tickets Sent</Card.Header>
      <Card.Body>
        <Row>
          <Column width={4}>
            <DateRangeInput
              id="game-date"
              label="Game Date"
              value={dateRange}
              onChange={updateDate}
              maxRange={{
                weeks: 2,
              }}
              withPortal
              popoverPlacement="bottom-end"
            />
          </Column>
          <Column width={4}>
            <Select
              id="home-team-dropdown"
              label="Home Team"
              options={teamsOptions}
              value={teamsOptions.find((opt) => {
                if (!!teamId) {
                  return opt.value.id === teamId;
                } else {
                  return !opt.value.id;
                }
              })}
              onChange={(team: ValueOpt<StatsTeam>) => updateTeamId(team?.value?.id)}
            />
          </Column>
          <Column width={4}>
            <Select
              id="type-dropdown"
              label="Type"
              options={digitalTicketTypeOptions}
              value={digitalTicketTypeOptions.find((opt: ValueOpt<DigitalTicketType>) => {
                if (!!type) {
                  return opt.value === type;
                } else {
                  return !opt.value;
                }
              })}
              onChange={(team: ValueOpt<DigitalTicketType>) => updateType(team?.value)}
            />
          </Column>
        </Row>
        <DataTable
          className="bcr-mt-2"
          data={displayTickets}
          columns={Cols}
          loading={loading}
          sortColumn={sortFilters["key"]}
          sortDirection={sortFilters["direction"]}
          sortFunction={(key, direction) => setSortFilters({ key, direction })}
          accordion={DigitalTicketDeliveryAccordion}
        />
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="primary" onClick={exportReport}>
            Export
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminDigitalTickets;
