import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getAdminPurchaseOpportunity, getPurchaseOpportunity, getAllPurchaseOpportunities } from "../api/RequesTixApi";

export const purchaseOpportunity = createQueryKeys("purchaseOpportunity", {
  getAdminPurchaseOpportunity: (purchaseOpportunityId: number | string) => ({
    queryKey: [purchaseOpportunityId],
    queryFn: () => getAdminPurchaseOpportunity(purchaseOpportunityId),
  }),
  getPurchaseOpportunity: (purchaseOpportunityId: number | string) => ({
    queryKey: [purchaseOpportunityId],
    queryFn: () => getPurchaseOpportunity(purchaseOpportunityId),
  }),
  getAllPurchaseOpportunities: (year: number) => ({
    queryKey: [year],
    queryFn: () => getAllPurchaseOpportunities(year),
  }),
});
