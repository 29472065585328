export default {
  USER_INFO: "userInfo",
  LAST_CACHE_TIME: "lastCacheTime",
  TEAMS: "teams",
  ROLES: "roles",
  STATES: "states",
  CARD_TYPES: "cardTypes",
  CARD_YEARS: "cardYears",
  SEASONS: "seasons",
  YEARS: "years",
  REQUEST_TYPES: "requestTypes",
  REQUEST_FULFILLMENT_TYPES: "requestFulfillmentTypes",
  REQUEST_STATES: "requestStates",
  REQUEST_CATEGORIES: "requestCategories",
  VENUE_DELIVERY_METHODS: "venueDeliveryMethods",
  USER_BILLING_COUNTRIES: "userBillingCountries",
  ADMIN_REQUESTS_HOME_TEAM_ID: "adminRequestsHomeTeamId",
  ADMIN_SUITE_REQUESTS_HOME_TEAM_ID: "adminSuiteRequestsHomeTeamId",
  SUITE_REQUEST_STATUSES: "suiteRequestStatuses",
  SERIES_TYPES: "seriesTypes",
  PURCHASE_OPPORTUNITY_STATUSES: "purchaseOpportunityStatuses",
  TODAYS_TICKETS_STATUSES: "todaysTicketsStatuses",
  OKTA_TOKEN: "okta-token-storage",
};
