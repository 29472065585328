import {
  Accordion,
  AttributeHeader,
  AttributeValue,
  Button,
  Card,
  CustomMobileAccordionType,
  DataTable,
  DataTableColumn,
  DataTableDataType,
  DateTimeFormatter,
  EditTableFormatter,
  StickyFooterButtons,
  urlReplace,
  useLoading,
  TeamLogoFormatter,
  YearSelect,
  Row,
  Column,
} from "best-common-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import RouteConstants from "../../../constants/RouteConstants";
import { PurchaseOpportunityDTO } from "../../../types/PurchaseOpportunity";
import { queries } from "../../../queries";

type PurchaseOppData = DataTableDataType & {
  purchaseOpportunityId?: number;
  teamId?: string;
  gameNumbers?: string;
  numOfGames?: number;
  seriesTypeDesc?: string;
  costDescription?: string;
  accessDate?: Date | string;
  cutoffDate?: Date | string;
  numOfWinners?: number;
  year?: number;
};

const MyPurchaseOppAccordion: React.FC<React.PropsWithChildren<CustomMobileAccordionType<PurchaseOppData>>> = ({
  data,
  index,
  ...rest
}) => {
  const navigate = useNavigate();

  const onEditClick = () => {
    navigate(urlReplace(RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.EDIT, { id: data.purchaseOpportunityId }));
  };

  const goToPurchaseOpportunity = () => {
    navigate(urlReplace(RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.VIEW, { id: data.purchaseOpportunityId }));
  };

  return (
    <Accordion {...rest}>
      <Accordion.Header>
        <div className="d-flex">
          <EditTableFormatter value={data.purchaseOpportunityId} row={data} onClick={onEditClick} />
          <div className="d-flex" onClick={goToPurchaseOpportunity}>
            <span className="bcr-mx-2">
              <TeamLogoFormatter value={data.teamId} />
            </span>
            {data.seriesTypeDesc}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div>
          <AttributeHeader>Num of games</AttributeHeader>
          <AttributeValue>{data.numOfGames}</AttributeValue>
        </div>
        <div>
          <AttributeHeader>Cost Desc</AttributeHeader>
          <AttributeValue>{data.costDescription}</AttributeValue>
        </div>
        <div>
          <AttributeHeader>Num of Winners</AttributeHeader>
          <AttributeValue>{data.numOfWinners}</AttributeValue>
        </div>
        <div>
          <AttributeHeader>Year</AttributeHeader>
          <AttributeValue>{data.year}</AttributeValue>
        </div>
        <div>
          <AttributeHeader>Access Date</AttributeHeader>
          <AttributeValue>
            <DateTimeFormatter value={data.accessDate} />
          </AttributeValue>
        </div>
        <div>
          <AttributeHeader>Cutoff Date</AttributeHeader>
          <AttributeValue>
            <DateTimeFormatter value={data.cutoffDate} />
          </AttributeValue>
        </div>
      </Accordion.Body>
    </Accordion>
  );
};

const convertPurchaseOpportunityToDataTable = (opp: PurchaseOpportunityDTO): PurchaseOppData => ({
  id: opp.purchaseOpportunityId,
  purchaseOpportunityId: opp.purchaseOpportunityId,
  teamId: opp.teamId,
  gameNumbers: opp.gameNumbers.map((gameNumber) => `Home Game #${gameNumber}`).join(", "),
  numOfGames: opp.gameNumbers.length,
  seriesTypeDesc: opp.seriesType.description,
  costDescription: opp.costDescription,
  accessDate: opp.accessDate as Date,
  cutoffDate: opp.cutoffDate as Date,
  numOfWinners: opp.numOfWinners,
  year: opp.year,
});

const AdminPurchaseOpportunity = () => {
  const navigate = useNavigate();
  const { loading } = useLoading();
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const { data: opportunities = [], isLoading } = useQuery({
    ...queries.purchaseOpportunity.getAllPurchaseOpportunities(year),
    select: (data: PurchaseOpportunityDTO[]) => {
      console.log("Query refetched for year:", year, "Data:", data);
      return data.map(convertPurchaseOpportunityToDataTable);
    },
    enabled: !!year,
  });

  const Columns: DataTableColumn<PurchaseOppData>[] = [
    {
      name: "",
      key: "purchaseOpportunityId",
      width: 40,
      onClick: (_rowIndex: number, row: PurchaseOppData) => {
        navigate(urlReplace(RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.EDIT, { id: row.purchaseOpportunityId }));
      },
      readonlyFormatter: EditTableFormatter,
    },
    {
      name: "Team",
      key: "teamId",
      width: 70,
      readonlyFormatter: TeamLogoFormatter,
      cellClass: "justify-content-center",
    },
    {
      name: "Series Type",
      key: "seriesTypeDesc",
      minWidth: 125,
    },
    {
      name: "Games",
      key: "gameNumbers",
      width: 350,
    },
    {
      name: "Year",
      key: "year",
      width: 100,
    },
    {
      name: "Cost Desc",
      key: "costDescription",
      minWidth: 150,
    },
    {
      name: "Num of Winners",
      key: "numOfWinners",
      width: 200,
      cellClass: "justify-content-center",
    },
    {
      name: "Access Date",
      key: "accessDate",
      readonlyFormatter: DateTimeFormatter,
      minWidth: 300,
    },
    {
      name: "Cutoff Date",
      key: "cutoffDate",
      readonlyFormatter: DateTimeFormatter,
      minWidth: 300,
    },
  ];

  const goToPurchaseOpportunity = (id: number | string) => {
    navigate(urlReplace(RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.VIEW, { id }));
  };

  const goToNewForm = () => {
    navigate(urlReplace(RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.EDIT, { id: -1 }));
  };

  return (
    <Card>
      <Card.Header>Purchase Opportunities</Card.Header>
      <Card.Body>
        <Row>
          <Column width={4}>
            <YearSelect
              id="year-select"
              label="Year"
              value={year}
              onChange={(value: number) => {
                setYear(value);
              }}
              gutterBottom
              minYear={2022}
              maxYear={new Date().getFullYear()}
            />
          </Column>
        </Row>
        <DataTable
          data={opportunities}
          columns={Columns}
          statusTextOverride={(_selectedCount: number, totalCount: number) => `${totalCount} Opportunities`}
          rowClick={{
            onClick: (_index: number, row: PurchaseOppData) => {
              goToPurchaseOpportunity(row.purchaseOpportunityId);
            },
          }}
          accordion={MyPurchaseOppAccordion}
          loading={loading || isLoading}
          tableHeights={{
            autogrow: true,
            maxHeightOffset: 400,
          }}
        />
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="default" onClick={goToNewForm}>
            New Opportunity
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminPurchaseOpportunity;
