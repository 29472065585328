import { AttributeHeader, AttributeValue, Button, Card, Icon, useTheme } from "best-common-react";
import React, { useState } from "react";
import styled from "styled-components";
import CreditCardMappings from "../../constants/CreditCardMappings";
import useCyberSource, { getCCExpInfo } from "../../util/CreditCardUtil";
import { getPhoneDisplay } from "../../util/PhoneUtil";
import { StateAbbreviation } from "../../util/StateUtil";
import DeleteCreditCardModal from "./DeleteCreditCardModal";
import EditCreditCard from "./EditCreditCard";

const CardHolder = styled.div.attrs(() => ({
  className: "bcr-me-2",
}))`
  width: 32.5rem;
  color: ${(props) => props.theme.color};
`;

const AddressValue = styled(AttributeValue)`
  &&& {
    margin: 0;
    margin-bottom: 0 !important;
  }
`;

const EndingIn = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.color};
`;

const Expiration = styled.div`
  color: ${(props) => props.color};
  font-size: 1rem;
`;

const SmallerAttributeHeader = styled(AttributeHeader)`
  &&& {
    font-size: 0.875rem;
  }
`;

const DefaultPaymentMethod = styled.b`
  font-size: 0.875rem;
  color: ${(props) => props.theme.color};
`;

export const CreditCardIcon = ({ type, size = 2, ...rest }) => (
  <i className={`fab ${type}`} style={{ fontSize: `${size}rem` }} {...rest} />
);

const CreditCardInfo = ({
  firstName,
  lastName,
  addressLine1,
  addressLine2,
  city,
  state,
  zip,
  phone,
  countryName,
  isDefault,
  onMakeDefault,
}) => (
  <div className="d-flex bcr-p-1">
    <div className="flex-grow-1">
      <SmallerAttributeHeader>Billing Address</SmallerAttributeHeader>
      <AddressValue>
        {firstName} {lastName}
      </AddressValue>
      <AddressValue>{addressLine1}</AddressValue>
      {addressLine2 && <AddressValue>{addressLine2}</AddressValue>}
      <AddressValue>
        {city}, <StateAbbreviation name={state} /> {zip}
      </AddressValue>
      <AddressValue>{countryName}</AddressValue>
      {!isDefault && (
        <Button variant="default" className="bcr-mt-3" onClick={onMakeDefault}>
          Make Default
        </Button>
      )}
    </div>
    <div>
      <SmallerAttributeHeader>Phone</SmallerAttributeHeader>
      <AttributeValue>{getPhoneDisplay(phone)}</AttributeValue>
    </div>
  </div>
);

const CreditCard = ({ userId, refresh, ...cc }) => {
  const { Theme } = useTheme();
  const { creditCard, setCreditCard, validCreditCard, onCancel, onSave, onDelete, makeDefault } = useCyberSource(
    cc,
    userId
  );
  const [number, setNumber] = React.useState("");
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validCard, setValidCard] = React.useState(false);

  const deleteCC = () => {
    setLoading(true);
    onDelete()
      .then(() => {
        setShowDeleteModal(false);
        refresh();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onClose = () => {
    onCancel();
    setShowEdit(false);
    setShowDeleteModal(false);
  };

  const onMakeDefault = () => {
    makeDefault().then(() => {
      refresh();
    });
  };

  const onSaveCard = async (number) => {
    await onSave(number);
    setShowEdit(false);
  };

  const ccExpInfo = getCCExpInfo(creditCard.ccExp, Theme);

  React.useEffect(() => {
    setValidCard(validCreditCard(creditCard));
  }, [creditCard]);

  return (
    <>
      <DeleteCreditCardModal
        show={showDeleteModal}
        onClose={onClose}
        onDelete={deleteCC}
        loading={loading}
        {...creditCard}
      />
      <CardHolder>
        <div>
          {creditCard.isDefault && !showEdit ? (
            <DefaultPaymentMethod>Default Payment Method</DefaultPaymentMethod>
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
        <Card>
          <Card.Header>
            <div className="d-flex bcr-p-1">
              <div className="d-flex align-items-center">
                <CreditCardIcon type={creditCard.ccType ? CreditCardMappings[creditCard.ccType.ccTypeName] : ""} />
              </div>
              <div className="bcr-ms-3 flex-grow-1">
                <EndingIn>
                  {creditCard.ccNickname} ending in {creditCard.ccLast4}
                </EndingIn>
                <Expiration color={ccExpInfo.color}>
                  {ccExpInfo.text} {creditCard.ccExp}
                </Expiration>
              </div>
              <div className="d-flex align-items-center">
                <Icon iconName="fa-pencil" className="bcr-me-2" disabled={showEdit} onClick={() => setShowEdit(true)} />
                <Icon iconName="fa-trash-alt" onClick={() => setShowDeleteModal(true)} />
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            {showEdit ? (
              <EditCreditCard
                cc={creditCard}
                setCC={setCreditCard}
                number={number}
                setNumber={setNumber}
                onCancel={onClose}
                onSave={() => onSaveCard(number)}
                valid={validCard}
              />
            ) : (
              <CreditCardInfo {...creditCard} onMakeDefault={onMakeDefault} />
            )}
          </Card.Body>
        </Card>
      </CardHolder>
    </>
  );
};

export default CreditCard;
