import { Action, ActionDTO, ActionStateDTO } from "../types/Action";
import { AdminRequestsDTO, AdminRequestSeriesDTO } from "../types/AdminRequest";
import { UserAllotment, UserAllotmentCountDTO, UserAllotmentDTO } from "../types/Allotment";
import { Business } from "../types/Business";
import { CreditCardType } from "../types/CreditCardType";
import {
  Department,
  DepartmentByGameDTO,
  DepartmentBySeasonDTO,
  DepartmentByYearDTO,
  UpdateDepartmentsBySeasonDTO,
} from "../types/Department";
import { DigitalTicketDeliveryDTO } from "../types/DigitalTickets";
import { EmailsSent } from "../types/Email";
import { EventBlackout } from "../types/EventBlackout";
import { Finance } from "../types/Finance";
import { AdminGameDTO, GameDTO, GamePreviewDTO, GameRequestCountMap } from "../types/Game";
import { MaxPriceOption } from "../types/MaxPriceOption";
import { NgeDTO, UpdateNgeDTO } from "../types/Nge";
import { Project, ProjectAllTotalsDTO, ProjectDTO, ProjectTotalsDTO } from "../types/Project";
import {
  AdminPurchaseOpportunityDTO,
  AdminPurchaseOpportunityEntryDTO,
  PurchaseOpportunityDTO,
  PurchaseOpportunityEntryDTO,
  PurchaseOpportunityEntryRecordDTO,
  PurchaseOpportunityStatus,
  SeriesTypeDTO,
} from "../types/PurchaseOpportunity";
import { ReportDataDTO, Reports } from "../types/Reports";
import { RequestsByVenueDTO } from "../types/RequestsByVenueDTO";
import { RequestCategory, RequestFulfillmentType, RequestState, RequestType } from "../types/RequestType";
import { Role } from "../types/Role";
import { SaveEventOverrides } from "../types/SaveEventOverrides";
import { Season } from "../types/Season";
import { State } from "../types/State";
import {
  AdminSuiteRequest,
  AdminSuiteRequestsDTO,
  SuiteRequestDtdMapping,
  SuiteRequestDTO,
  SuiteRequestSaveDTO,
  SuiteRequestStatus,
} from "../types/SuiteRequest";
import { SaveTicketRequestDTO, TicketRequest } from "../types/TicketRequest";
import { BoxOfficeTicketsDTO } from "../types/Tickets";
import {
  AdminDayOfTicket,
  DayOfGameDTO,
  DayOfGameRequestDTO,
  DayOfRequestDTO,
  DayOfRequestStatus,
  DayOfUnsentTicketDTO,
  VenueDayOfDTO,
} from "../types/TodaysTickets";
import { TransactionHistory } from "../types/Transaction";
import { CreateUserDTO, User, UserDTO, UserInfo } from "../types/User";
import { BillingDTO, BillingTransactionDTO, UserBillingCountry } from "../types/UserBilling";
import { ReconciliationTicketRequestDTO, UserRequestAggregationDTO, UserTicketRequestDTO } from "../types/UserRequest";
import {
  VenueAcceptedBilling,
  VenueBlackoutDTO,
  VenueCutoffOverride,
  VenueDeliveryMethod,
  VenueDeliveryMethodOptions,
  VenueDTO,
} from "../types/Venue";
import RequestixClient from "./RequestixClient";
import { StatsGame, StatsTeam } from "best-common-react";

const { SERVICES_URL } = window.MLBBest.envVariables || {};

export const getCurrentUser = async (): Promise<UserInfo> => (await RequestixClient.get(`/user/current`)).data.entity;

export const getUser = async (id: string | number): Promise<UserDTO> =>
  (await RequestixClient.get(`/user/${id}`)).data.entity;

export const updateUser = async (user: UserDTO): Promise<UserDTO> =>
  (await RequestixClient.put(`/user`, user)).data.entity;

export const getUsers = async (departmentId: number | string, seasonId: number | string): Promise<UserDTO[]> => {
  const department: string = departmentId ? `&department=${departmentId}` : "";
  return (await RequestixClient.get(`/admin/users?season=${seasonId}${department}`)).data.entity;
};

export const getTeams = async (): Promise<StatsTeam[]> => (await RequestixClient.get(`/teams`)).data.entity;

export const getAdminSchedule = async (
  homeTeamId: number,
  opponentId: number,
  startDate: string,
  endDate: string
): Promise<AdminGameDTO[]> => {
  const homeTeam: string = homeTeamId ? `&homeTeamId=${homeTeamId}` : "";
  const opponent: string = opponentId ? `&opponentId=${opponentId}` : "";
  return (await RequestixClient.get(`/admin/schedule?startDate=${startDate}&endDate=${endDate}${homeTeam}${opponent}`))
    .data.entity;
};

export const getAdminSchedulePreview = async (
  homeTeamId: number,
  startDate: string,
  endDate: string,
  showAlert = true
): Promise<GamePreviewDTO[]> =>
  (
    await RequestixClient.get(
      `/admin/schedule/preview?homeTeamId=${homeTeamId}&startDate=${startDate}&endDate=${endDate}&showAlert=${showAlert}`
    )
  ).data.entity;

export const getSchedule = async (
  homeTeamId: number | string,
  opponentId: number | string,
  startDate: string,
  endDate: string
): Promise<GameDTO[]> => {
  const homeTeam: string = homeTeamId ? `&homeTeamId=${homeTeamId}` : "";
  const opponent: string = opponentId ? `&opponentId=${opponentId}` : "";
  return (await RequestixClient.get(`/schedule?startDate=${startDate}&endDate=${endDate}${homeTeam}${opponent}`)).data
    .entity;
};

export const getEvent = async (eventId: string | number): Promise<GameDTO> =>
  (await RequestixClient.get(`/schedule/${eventId}`)).data.entity;

export const getEventDeliveryMethods = async (eventId: string | number): Promise<VenueDeliveryMethod[]> =>
  (await RequestixClient.get(`/schedule/${eventId}/deliveryMethods`)).data.entity;

export const getRequestSurroundingGames = async (requestId: number): Promise<GamePreviewDTO[]> =>
  (await RequestixClient.get(`/admin/requests/${requestId}/surroundingGames`)).data.entity;

export const getVenues = async (season: number): Promise<VenueDTO[]> =>
  (await RequestixClient.get(`/admin/venues?season=${season}`)).data.entity;

export const getVenue = async (id: number, season: number): Promise<VenueDTO> =>
  (await RequestixClient.get(`/admin/venues/${id}?season=${season}`)).data.entity;

export const getVenueOverride = async (id: number): Promise<VenueCutoffOverride> =>
  (await RequestixClient.get(`/admin/venues/cutoff/override/${id}`)).data.entity;

export const saveVenueOverride = async (
  id: number,
  cutoffOverride: VenueCutoffOverride
): Promise<VenueCutoffOverride> =>
  (await RequestixClient.post(`/admin/venues/cutoff/override/${id}`, cutoffOverride)).data.entity;

export const updateVenue = async (venue: VenueDTO): Promise<any> =>
  (await RequestixClient.put(`/admin/venues`, venue)).data.entity;

export const putCopyVenuesFromYearToYear = async (fromYear: number, toYear: number): Promise<any> =>
  (await RequestixClient.put(`/admin/venues/copy/from/${fromYear}/to/${toYear}`)).data.entity;

export const getVenueAcceptedBillings = async (venueId: number): Promise<VenueAcceptedBilling[]> =>
  (await RequestixClient.get(`/venue/billings/${venueId}`)).data.entity;

/* Departments */
export const getDepartments = async (): Promise<Department[]> =>
  (await RequestixClient.get(`/admin/departments/active`)).data.entity;

export const getDepartmentsBySeason = async (seasonId: number | string): Promise<DepartmentBySeasonDTO[]> =>
  (await RequestixClient.get(`/admin/departments/bySeason?seasonId=${seasonId}`)).data.entity;

export const updateDepartmentsBySeason = async (
  departmentIds: number[],
  season: Season,
  update: UpdateDepartmentsBySeasonDTO
): Promise<DepartmentBySeasonDTO[]> => {
  const body: UpdateDepartmentsBySeasonDTO = { departmentIds, season, ...update };
  return (await RequestixClient.put(`/admin/departments/bySeason`, body)).data.entity;
};

export const updateDepartment = async (body: DepartmentByYearDTO): Promise<any> =>
  (await RequestixClient.put(`/admin/departments`, body)).data.entity;

export const getDepartmentByYear = async (id: number, year: number): Promise<DepartmentByYearDTO> =>
  (await RequestixClient.get(`/admin/departments/${id}?year=${year}`)).data.entity;

export const getDepartmentsByEvent = async (eventId: string): Promise<DepartmentByGameDTO[]> =>
  (await RequestixClient.get(`/admin/departments/byEvent?eventId=${eventId}`)).data.entity;

export const saveEventOverrides = async (overrides: SaveEventOverrides): Promise<any> =>
  (await RequestixClient.post(`/admin/events/overrides`, overrides)).data.entity;

export const deleteEventOverrides = async (departmentId: number, eventId: string): Promise<any> =>
  (await RequestixClient.delete(`/admin/events/overrides?departmentId=${departmentId}&eventId=${eventId}`)).data.entity;

export const syncTdcUsers = async (users: CreateUserDTO[]): Promise<any> =>
  (await RequestixClient.post(`/admin/tdc/sync`, users)).data.entity;

export const createUser = async (user: CreateUserDTO): Promise<any> =>
  (await RequestixClient.post(`/admin/user`, user)).data.entity;

/* Seasons */
export const getSeasons = async (): Promise<Season[]> => (await RequestixClient.get(`/seasons`)).data.entity;

export const getActiveSeason = async (): Promise<Season> => (await RequestixClient.get(`/seasons/active`)).data.entity;

export const getRoles = async (): Promise<Role[]> => (await RequestixClient.get(`/roles`)).data.entity;

export const getStates = async (): Promise<State[]> => (await RequestixClient.get(`/states`)).data.entity;

export const getCardTypes = async (): Promise<CreditCardType[]> =>
  (await RequestixClient.get(`/cardTypes`)).data.entity;

export const getVenueDeliveryMethodOptions = async (): Promise<VenueDeliveryMethodOptions[]> =>
  (await RequestixClient.get(`/venue/deliveryOptions`)).data.entity;

export const getUserBillingCountries = async (): Promise<UserBillingCountry[]> =>
  (await RequestixClient.get(`/user/billing/countries`)).data.entity;

export const updateUserAllotment = async (userAllotments: UserAllotmentDTO[]): Promise<UserAllotment[]> =>
  (await RequestixClient.post(`/admin/allotment/user`, userAllotments)).data.entity;

/* Ticket Request */
export const getRequestTypes = async (): Promise<RequestType[]> =>
  (await RequestixClient.get(`/requests/types`)).data.entity;

export const getRequestFulfillmentTypes = async (): Promise<RequestFulfillmentType[]> =>
  (await RequestixClient.get(`/requests/fulfillmentTypes`)).data.entity;

export const getRequestCategories = async (): Promise<RequestCategory[]> =>
  (await RequestixClient.get(`/requests/categories`)).data.entity;

export const deleteTicketRequest = async (requestId: number): Promise<any> =>
  (await RequestixClient.delete(`/requests/${requestId}`)).data.entity;

export const getAllotmentByRequest = async (requestId: number): Promise<UserAllotmentCountDTO> =>
  (await RequestixClient.get(`/admin/requests/${requestId}/allotment`)).data.entity;

export const getRequestStates = async (): Promise<RequestState[]> =>
  (await RequestixClient.get(`/requests/states`)).data.entity;

export const adminSaveRequest = async (
  request: SaveTicketRequestDTO,
  showSuccessAlert?: boolean
): Promise<TicketRequest> => {
  const showSuccessAlertQuery: string = !!showSuccessAlert ? "?showSuccessAlert=true" : "?showSuccessAlert=false";
  return (await RequestixClient.post(`/admin/requests${showSuccessAlertQuery}`, request)).data.entity;
};

export const saveRequest = async (
  request: SaveTicketRequestDTO,
  showSuccessAlert?: boolean
): Promise<TicketRequest> => {
  const showSuccessAlertQuery: string =
    showSuccessAlert == null || showSuccessAlert == true ? "?showSuccessAlert=true" : "?showSuccessAlert=false";
  return (await RequestixClient.post(`/requests${showSuccessAlertQuery}`, request)).data.entity;
};

export const getSurroundingGames = async (gamePk: number | string): Promise<StatsGame[]> =>
  (await RequestixClient.get(`/game/${gamePk}/surrounding`)).data.entity;

export const changeRequestGame = async (requestId: number, gamePk: number): Promise<TicketRequest> =>
  (await RequestixClient.put(`/admin/requests/${requestId}/surroundingGames/${gamePk}`)).data.entity;

export const personalAllowedForDepartmentAndYear = async (
  departmentId: number,
  year: number
): Promise<Map<string, boolean>> =>
  (await RequestixClient.get(`/requests/personal/department/${departmentId}/year/${year}`)).data.entity;

/* Businesses */
export const getBusinesses = async (): Promise<Business[]> => (await RequestixClient.get(`/business`)).data.entity;

export const createBusiness = async (businessName: string): Promise<Business> =>
  (await RequestixClient.post(`/business`, { businessName: businessName })).data.entity;

/* Credit Card */
export const getUserBillings = async (userId: number | string): Promise<BillingDTO[]> =>
  (await RequestixClient.get(`/user/${userId}/billing`)).data.entity;

export const saveCreditCard = async (userId: number | string, creditCard: BillingDTO): Promise<BillingDTO> =>
  (await RequestixClient.post(`/user/${userId}/billing`, creditCard)).data.entity;

export const deleteCreditCard = async (userId: number | string, userBillingId: number): Promise<any> =>
  (await RequestixClient.delete(`/user/${userId}/billing/${userBillingId}`)).data.entity;

/* Projects */
export const saveProject = async (project: ProjectDTO): Promise<Project> =>
  (await RequestixClient.post(`/admin/projects`, project)).data.entity;

export const getProjects = async (): Promise<Project[]> => (await RequestixClient.get(`/admin/projects`)).data.entity;

export const getProject = async (projectId: number): Promise<Project> =>
  (await RequestixClient.get(`/admin/projects/${projectId}`)).data.entity;

export const getUserProjects = async (userId: number): Promise<Project[]> =>
  (await RequestixClient.get(`/user/${userId}/projects`)).data.entity;

/* Graphql */
export const graphQl = (queryObj) =>
  RequestixClient.post(`${SERVICES_URL}/graphql`, queryObj).then((response) => response.data.data);

export const getUserAllotmentCounts = async (
  userId: number | string,
  year: number | string
): Promise<UserAllotmentCountDTO[]> =>
  (await RequestixClient.get(`/user/${userId}/allotment/count/${year}`)).data.entity;

export const getAdminRequests = async (
  startDate: string,
  endDate: string,
  homeTeamId: number,
  stateId: number,
  categoryId: number,
  completeId: number
): Promise<AdminRequestsDTO> => {
  const homeTeamQuery: string = homeTeamId ? `&homeTeamId=${homeTeamId}` : "";
  const stateQuery: string = stateId ? `&stateId=${stateId}` : "";
  const categoryQuery: string = categoryId ? `&categoryId=${categoryId}` : "";
  const completeQuery: string = completeId ? `&completeId=${completeId}` : "";
  return (
    await RequestixClient.get(
      `/admin/requests?startDate=${startDate}&endDate=${endDate}${homeTeamQuery}${stateQuery}${categoryQuery}${completeQuery}`
    )
  ).data.entity;
};

export const getAdminRequestsBySeries = async (
  startDate: string,
  endDate: string,
  homeTeamId?: number,
  stateId?: number,
  completeId?: number
): Promise<AdminRequestSeriesDTO[]> => {
  const homeTeamQuery: string = homeTeamId ? `&homeTeamId=${homeTeamId}` : "";
  const stateQuery: string = stateId ? `&stateId=${stateId}` : "";
  const completeQuery: string = completeId ? `&completeId=${completeId}` : "";
  return (
    await RequestixClient.get(
      `/admin/requests/series?startDate=${startDate}&endDate=${endDate}${homeTeamQuery}${stateQuery}${completeQuery}`
    )
  ).data.entity;
};

export const getRequestsByGamePk = async (
  gamePk: string,
  stateId: number[],
  categoryId: number,
  completeId: number
): Promise<AdminRequestsDTO> => {
  const stateQuery: string = stateId ? `&stateId=${stateId}` : "";
  const categoryQuery: string = categoryId ? `&categoryId=${categoryId}` : "";
  const completeQuery: string = completeId ? `&completeId=${completeId}` : "";
  return (await RequestixClient.get(`/admin/requests/${gamePk}?${stateQuery}${categoryQuery}${completeQuery}`)).data
    .entity;
};

export const getRequestsBySeriesNumber = async (
  season: number | string,
  homeTeamId: number | string,
  seriesNumber: number | string,
  gameType: string,
  stateId: number[] | string[],
  categoryId: number | string,
  completeId: number | string
): Promise<AdminRequestsDTO> => {
  const stateQuery = stateId ? `&stateId=${stateId}` : "";
  const categoryQuery = categoryId ? `&categoryId=${categoryId}` : "";
  const completeQuery = completeId ? `&completeId=${completeId}` : "";
  return (
    await RequestixClient.get(
      `/admin/requests/series/${seriesNumber}/team/${homeTeamId}/season/${season}/gameType/${gameType}?${stateQuery}${categoryQuery}${completeQuery}`
    )
  ).data.entity;
};

export const getRequestActions = async (requestId: number): Promise<ActionDTO[]> =>
  (await RequestixClient.get(`/admin/requests/${requestId}/actions`)).data.entity;

export const getRequestActionState = async (requestId: number): Promise<ActionStateDTO> =>
  (await RequestixClient.get(`/admin/requests/${requestId}/actionState`)).data.entity;

export const getActionsForState = async (stateId: number): Promise<Action[]> =>
  (await RequestixClient.get(`/admin/requests/state/${stateId}`)).data.entity;

export const updateRequestState = async (
  requestId: number,
  stateId: number,
  actionState: ActionStateDTO,
  showSuccessAlert = true
): Promise<any> => {
  const showSuccessAlertQuery: string =
    showSuccessAlert == null || showSuccessAlert == true ? "?showSuccessAlert=true" : "?showSuccessAlert=false";
  return (
    await RequestixClient.put(`/admin/requests/${requestId}/state/${stateId}${showSuccessAlertQuery}`, actionState)
  ).data.entity;
};

export const getRequest = async (requestId: number | string): Promise<TicketRequest> =>
  (await RequestixClient.get(`/requests/${requestId}`)).data.entity;

export const billingTransaction = async (txDTO: BillingTransactionDTO): Promise<TransactionHistory> =>
  (await RequestixClient.post(`/user/billing/transaction`, txDTO)).data.entity;

export const sendConfirmationEmail = async (actionStateDTO: ActionStateDTO, emailType: number): Promise<EmailsSent> =>
  (await RequestixClient.post(`/email/confirmation?emailType=${emailType}`, actionStateDTO)).data.entity;

export const getCacheRefreshTime = async (): Promise<Date | string> =>
  (await RequestixClient.get(`/cache/time`)).data.entity;

export const getRequestOnBehalfOf = async (userId: number): Promise<User[]> =>
  (await RequestixClient.get(`/requests/user/${userId}/requestOnBehalfOf`)).data.entity;

export const getUsersRequests = async (
  requesterUserId?: number,
  submitterUserId?: number,
  gameSearchId?: number,
  categoryId?: number,
  status: string | undefined = "",
  seasonId?: number,
  fulfillmentTypeId?: number,
  departmentId?: number
): Promise<UserTicketRequestDTO[]> => {
  const requesterUserIdQuery: string = requesterUserId ? `&requesterUserId=${requesterUserId}` : "";
  const submitterUserIdQuery: string = submitterUserId ? `&submitterUserId=${submitterUserId}` : "";
  const gameSearchQuery: string = gameSearchId ? `&gameSearchId=${gameSearchId}` : "";
  const categoryQuery: string = categoryId ? `&categoryId=${categoryId}` : "";
  const stateQuery: string = status.length > 0 ? `&stateId=${status}` : "";
  const seasonIdQuery: string = seasonId ? `&seasonId=${seasonId}` : "";
  const fulfillmentTypeIdQuery: string = fulfillmentTypeId ? `&fulfillmentTypeId=${fulfillmentTypeId}` : "";
  const departmentIdQuery: string = departmentId ? `&departmentId=${departmentId}` : "";
  return (
    await RequestixClient.get(
      `/requests/user/requests?${requesterUserIdQuery}${submitterUserIdQuery}${gameSearchQuery}${categoryQuery}${stateQuery}${seasonIdQuery}${fulfillmentTypeIdQuery}${departmentIdQuery}`
    )
  ).data.entity;
};

export const getAllUsersRequests = async (
  gameSearchId: number,
  categoryId: number,
  status = "",
  seasonId: number,
  fulfillmentTypeId: number,
  departmentId: number
): Promise<UserRequestAggregationDTO[]> => {
  const gameSearchQuery: string = gameSearchId ? `&gameSearchId=${gameSearchId}` : "";
  const categoryQuery: string = categoryId ? `&categoryId=${categoryId}` : "";
  const stateQuery: string = status.length > 0 ? `&stateId=${status}` : "";
  const seasonIdQuery: string = seasonId ? `&seasonId=${seasonId}` : "";
  const fulfillmentTypeIdQuery: string = fulfillmentTypeId ? `&fulfillmentTypeId=${fulfillmentTypeId}` : "";
  const departmentIdQuery: string = departmentId ? `&departmentId=${departmentId}` : "";
  return (
    await RequestixClient.get(
      `/admin/requests/users?${gameSearchQuery}${categoryQuery}${stateQuery}${seasonIdQuery}${fulfillmentTypeIdQuery}${departmentIdQuery}`
    )
  ).data.entity;
};

export const getGameRequestCounts = async (gamePks: number[]): Promise<GameRequestCountMap> =>
  (await RequestixClient.post(`/game/requestCounts`, gamePks)).data.entity;

export const getReports = async (): Promise<Reports[]> => (await RequestixClient.get(`/reports`)).data.entity;

export const canViewReport = async (reportId: number | string): Promise<boolean> =>
  (await RequestixClient.get(`/reports/canView/${reportId}`)).data.entity;

export const getReport = async (reportId: number | string): Promise<Reports> =>
  (await RequestixClient.get(`/reports/${reportId}`)).data.entity;

export const runReport = async (reportId: number | string, reportData: ReportDataDTO) => {
  const url = `/reports/${reportId}`;
  return RequestixClient({
    url: url,
    method: "POST",
    data: reportData,
    responseType: "blob", // important
  });
};

export const getProjectRequests = async (
  projectId: number,
  startDate: string,
  endDate: string,
  statusId: number,
  fulfillmentTypeId: number
): Promise<UserTicketRequestDTO[]> => {
  const statusQuery: string = statusId ? `&statusId=${statusId}` : "";
  const fulfillmentQuery: string = fulfillmentTypeId ? `&fulfillmentTypeId=${fulfillmentTypeId}` : "";
  const url = `/project/${projectId}/requests?startDate=${startDate}&endDate=${endDate}${statusQuery}${fulfillmentQuery}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const activeSeasonSync = async (): Promise<any> => {
  const url = `/admin/sync/activeSeason`;
  return (await RequestixClient.get(url)).data.entity;
};

export const getProjectRequestsTotals = async (
  projectId: number,
  startDate: string,
  endDate: string
): Promise<ProjectTotalsDTO> => {
  const url = `/project/${projectId}/totals?startDate=${startDate}&endDate=${endDate}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const getAllProjectRequestsTotals = async (
  startDate: string,
  endDate: string
): Promise<ProjectAllTotalsDTO[]> => {
  const url = `/projects/requests?startDate=${startDate}&endDate=${endDate}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const getAllNges = async (year: number): Promise<NgeDTO[]> => {
  const url = `/admin/postseason/nge/${year}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const getPostSeasonEvents = async (year: number): Promise<StatsGame[]> => {
  const url = `/admin/postseason/events/${year}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const updateNgeMapping = async (updateNgeDto: UpdateNgeDTO): Promise<any> => {
  const url = `/admin/postseason/nge`;
  return (await RequestixClient.put(url, updateNgeDto)).data;
};

export const bulkRefundGame = async (gamePk: number): Promise<any> => {
  const url = `/admin/transaction/bulk/refund/${gamePk}`;
  return (await RequestixClient.put(url)).data;
};

export const getVenueBlackouts = async (venueId: number): Promise<VenueBlackoutDTO[]> => {
  const url = `/admin/blackout/venue/${venueId}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const deleteVenueBlackout = async (venueBlackoutId: number): Promise<any> => {
  const url = `/admin/blackout/venue/${venueBlackoutId}`;
  return (await RequestixClient.delete(url)).data;
};

export const deleteVenueBlackouts = async (venueBlackoutIds: number[]): Promise<any> => {
  const url = `/admin/blackout/venue/bulkDelete`;
  return (await RequestixClient.post(url, venueBlackoutIds)).data;
};

export const addVenueBlackout = async (venueBlackoutDTO: VenueBlackoutDTO): Promise<VenueBlackoutDTO> => {
  const url = `/admin/blackout/venue`;
  return (await RequestixClient.post(url, venueBlackoutDTO)).data.entity;
};

export const getEventBlackouts = async (eventId: string): Promise<boolean> => {
  const url = `/admin/blackout/event/${eventId}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const deleteEventBlackout = async (eventId: string): Promise<any> => {
  const url = `/admin/blackout/event/${eventId}`;
  return (await RequestixClient.delete(url)).data;
};

export const addEventBlackout = async (eventId: string): Promise<EventBlackout> => {
  const url = `/admin/blackout/event/${eventId}`;
  return (await RequestixClient.post(url)).data.entity;
};

export const getDigitalTickets = async (
  eventId: string | number,
  teamId: number | string,
  refresh = false
): Promise<BoxOfficeTicketsDTO> => {
  const url = `/admin/ticketDelivery/getTickets?eventId=${eventId}&teamId=${teamId}&refresh=${refresh}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const resendTickets = async (requestId: number): Promise<any> => {
  const url = `/admin/ticketDelivery/resendTickets/${requestId}`;
  return (await RequestixClient.put(url)).data.entity;
};

export const getDigitalTicketsRecords = async (
  startDate?: string,
  endDate?: string,
  teamId?: number,
  type?: string
): Promise<DigitalTicketDeliveryDTO[]> => {
  const startQuery = !!startDate ? `startDate=${startDate}` : "";
  const endQuery = !!endDate ? `&endDate=${endDate}` : "";
  const teamQuery = !!teamId ? `&homeTeamId=${teamId}` : "";
  const typeQuery = !!type ? `&type=${type}` : "";
  const query = `?${startQuery}${endQuery}${teamQuery}${typeQuery}`;
  const url = `/admin/digitalTickets/records${query}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const exportDigitalTicketsReport = async (
  startDate?: string,
  endDate?: string,
  teamId?: number,
  type?: string
) => {
  const startQuery = !!startDate ? `startDate=${startDate}` : "";
  const endQuery = !!endDate ? `&endDate=${endDate}` : "";
  const teamQuery = !!teamId ? `&homeTeamId=${teamId}` : "";
  const typeQuery = !!type ? `&type=${type}` : "";
  const query = `?${startQuery}${endQuery}${teamQuery}${typeQuery}`;
  const url = `/admin/digitalTickets/records/export${query}`;
  return RequestixClient({
    url: url,
    method: "POST",
    responseType: "blob", // important
  });
};

export const sendTicketReminder = async (state: ActionStateDTO): Promise<EmailsSent> => {
  const url = `/email/reminder`;
  return (await RequestixClient.post(url, state)).data.entity;
};

export const getReconciliationRequests = async (
  startDate: string,
  endDate: string,
  fulfillmentTypeId: number,
  homeTeamId: number
): Promise<ReconciliationTicketRequestDTO[]> => {
  const fulfillmentQuery: string = fulfillmentTypeId ? `&fulfillmentTypeId=${fulfillmentTypeId}` : "";
  const homeTeamQuery: string = homeTeamId ? `&homeTeamId=${homeTeamId}` : "";
  const url = `/requests/reconciliation/requests?startDate=${startDate}&endDate=${endDate}${fulfillmentQuery}${homeTeamQuery}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const getTicketRequestCharges = async (startDate: string, endDate: string): Promise<Finance[]> => {
  const url = `/finance/ticketRequest/charges?startDate=${startDate}&endDate=${endDate}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const getTicketRequestRefunds = async (startDate: string, endDate: string): Promise<Finance[]> => {
  const url = `/finance/ticketRequest/refunds?startDate=${startDate}&endDate=${endDate}`;
  return (await RequestixClient.get(url)).data.entity;
};
export const getPurchaseOppCharges = async (startDate: string, endDate: string): Promise<Finance[]> => {
  const url = `/finance/purchaseOpp/charges?startDate=${startDate}&endDate=${endDate}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const getPurchaseOppRefunds = async (startDate: string, endDate: string): Promise<Finance[]> => {
  const url = `/finance/purchaseOpp/refunds?startDate=${startDate}&endDate=${endDate}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const getReceiptPDF = async (requestId: number): Promise<any> => {
  const url = `/admin/request/${requestId}/receipt`;
  return await RequestixClient({
    url: url,
    method: "GET",
    responseType: "blob", // important
  });
};

export const getSeasonTickets = async (
  teamId: number,
  startDate: Date,
  endDate: Date,
  departmentId: number
): Promise<UserTicketRequestDTO[]> => {
  const departmentQuery: string = departmentId ? `&departmentId=${departmentId}` : "";
  const url = `/admin/seasonTickets?teamId=${teamId}&startDate=${startDate}&endDate=${endDate}${departmentQuery}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const getUnusedSeasonTickets = async (teamId: number, startDate: string, endDate: string): Promise<any> => {
  const url = `/admin/seasonTickets/unused?teamId=${teamId}&startDate=${startDate}&endDate=${endDate}`;
  return await RequestixClient({
    url: url,
    method: "POST",
    responseType: "blob", // important
  });
};

export const checkForSeasonTickets = async (teamId: number, startDate: string, endDate: string): Promise<any> => {
  const url = `/admin/seasonTickets/check?teamId=${teamId}&startDate=${startDate}&endDate=${endDate}`;
  return (await RequestixClient.post(url)).data.entity;
};

export const getRequestsByVenueForSeasonId = async (seasonId: number): Promise<RequestsByVenueDTO[]> =>
  (await RequestixClient.get(`/admin/requestsByVenue/${seasonId}`)).data.entity;

export const getDayOfGames = async (): Promise<DayOfGameDTO[]> =>
  (await RequestixClient.get(`/dayOf/games`)).data.entity;

export const getDayOfAllotment = async (): Promise<number> =>
  (await RequestixClient.get(`/dayOf/allotment`)).data.entity;

export const getMaxPriceOptions = async (): Promise<MaxPriceOption[]> =>
  (await RequestixClient.get(`/requests/maxPriceOptions`)).data.entity;

export const sendDayOfRequest = async (request: DayOfRequestDTO): Promise<any> =>
  (await RequestixClient.post(`/dayOf/requestTickets`, request)).data;

export const getVenueDayOf = async (venueId: number): Promise<VenueDayOfDTO> =>
  (await RequestixClient.get(`/admin/dayOf/venue/${venueId}`)).data.entity;

export const updateVenueDayOf = async (venueDayOfDTO: VenueDayOfDTO): Promise<any> =>
  (await RequestixClient.post(`/admin/dayOf/venue/${venueDayOfDTO.venueId}`, venueDayOfDTO)).data;

export const getAllDayOfTickets = async (): Promise<AdminDayOfTicket[]> =>
  (await RequestixClient.get(`/admin/dayOf/all/tickets`)).data.entity;

export const deleteDayOfTicket = async (dayOfTicketId: number): Promise<any> =>
  (await RequestixClient.delete(`/admin/dayOf/ticket/delete/${dayOfTicketId}`)).data;

export const deleteDayOfTicketRequest = async (dayOfTicketRequestId: number): Promise<any> =>
  (await RequestixClient.delete(`/admin/dayOf/ticket/request/delete/${dayOfTicketRequestId}`)).data;

export const getDayOfRequests = async (
  startDate: string,
  endDate: string,
  teamId: number,
  departmentId?: number
): Promise<DayOfGameRequestDTO[]> => {
  const query = `startDate=${startDate}&endDate=${endDate}${!!teamId ? `&teamId=${teamId}` : ""}${
    !!departmentId ? `&departmentId=${departmentId}` : ""
  }`;
  return (await RequestixClient.get(`/admin/dayOf/requests?${query}`)).data.entity;
};

export const getDayOfRequest = async (requestId: string): Promise<DayOfGameRequestDTO> =>
  (await RequestixClient.get(`/admin/dayOf/requests/${requestId}`)).data.entity;

export const revokeDayOfRequest = async (requestId: number): Promise<any> => {
  return (await RequestixClient.post(`/admin/dayOf/revokeTickets/${requestId}`)).data.entity;
};

export const cancelDayOfGame = async (gamePk: string): Promise<any> =>
  (await RequestixClient.post(`/admin/dayOf/cancel/${gamePk}`)).data.entity;

export const removeDayOfCancellation = async (gamePk: string): Promise<any> =>
  (await RequestixClient.delete(`/admin/dayOf/cancel/${gamePk}`)).data.entity;

export const getTodaysDigitalTickets = async (
  eventId: string | number,
  teamId: string | number
): Promise<DayOfUnsentTicketDTO[]> => {
  const url = `/dayOf/getTickets?eventId=${eventId}&teamId=${teamId}`;
  return (await RequestixClient.get(url)).data.entity;
};

export const getCloseCutoffRequests = async (
  startDate: string,
  endDate: string,
  departmentId?: number,
  categoryId?: number
): Promise<ReconciliationTicketRequestDTO[]> => {
  const query = `${departmentId ? `&departmentId=${departmentId}` : ""}${
    categoryId ? `&categoryId=${categoryId}` : ""
  }`;
  return (await RequestixClient.get(`/requests/closeCutoff?startDate=${startDate}&endDate=${endDate}${query}`)).data
    .entity;
};

export const getAllSuiteRequestStatuses = async (): Promise<SuiteRequestStatus[]> =>
  (await RequestixClient.get(`/suite/request/statuses`)).data.entity;

export const getMySuiteRequests = async (year: number): Promise<SuiteRequestDTO[]> =>
  (await RequestixClient.get(`/suite/request/all?year=${year}`)).data.entity;

export const getSuiteRequest = async (suiteRequestId: number | string): Promise<SuiteRequestSaveDTO> =>
  (await RequestixClient.get(`/suite/request/${suiteRequestId}`)).data.entity;

export const saveSuiteRequest = async (suiteRequestDTO: SuiteRequestSaveDTO): Promise<SuiteRequestSaveDTO> =>
  (await RequestixClient.post(`/suite/request`, suiteRequestDTO)).data.entity;

export const getIsLottoActive = async (): Promise<boolean> =>
  (await RequestixClient.get(`/dayOf/isLottoActive`)).data.entity;

export const getAllSeriesTypes = async (): Promise<SeriesTypeDTO[]> =>
  (await RequestixClient.get("/purchaseOpportunity/seriesTypes")).data.entity;

export const getPurchaseOpportunity = async (id: number | string): Promise<PurchaseOpportunityDTO> =>
  (await RequestixClient.get(`/purchaseOpportunity/${id}`)).data.entity;
export const getActivePurchaseOpportunities = async (): Promise<PurchaseOpportunityDTO[]> =>
  (await RequestixClient.get("/purchaseOpportunity/active")).data.entity;

export const getAllPurchaseOpportunities = async (year: number): Promise<PurchaseOpportunityDTO[]> =>
  (await RequestixClient.get(`/admin/purchaseOpportunity?year=${year}`)).data.entity;

export const getAdminPurchaseOpportunity = async (id: number | string): Promise<AdminPurchaseOpportunityDTO> =>
  (await RequestixClient.get(`/admin/purchaseOpportunity/${id}`)).data.entity;

export const getAdminPurchaseOpportunityEntriesForUser = async (
  purchaseOpportunityId: number | string,
  userId: number | string
): Promise<AdminPurchaseOpportunityEntryDTO[]> =>
  (await RequestixClient.get(`/admin/purchaseOpportunity/${purchaseOpportunityId}/user/${userId}`)).data.entity;

export const savePurchaseOpportunity = async (obj: PurchaseOpportunityDTO): Promise<PurchaseOpportunityDTO> =>
  (await RequestixClient.post("/admin/purchaseOpportunity", obj)).data.entity;

export const createPurchaseEntry = async (
  obj: PurchaseOpportunityEntryRecordDTO
): Promise<PurchaseOpportunityEntryDTO[]> =>
  (await RequestixClient.post("/purchaseOpportunity/entry", obj)).data.entity;

export const updatePurchaseEntry = async (
  obj: PurchaseOpportunityEntryRecordDTO
): Promise<PurchaseOpportunityEntryDTO[]> => (await RequestixClient.put("/purchaseOpportunity/entry", obj)).data.entity;

export const exportPurchaseOpportunity = async (id: number) => {
  const url = `/admin/purchaseOpportunity/export/${id}`;
  return RequestixClient({
    url: url,
    method: "GET",
    responseType: "blob", // important
  });
};

export const getPurchaseEntry = async (
  purchaseOpportunityId: number | string,
  purchaseOpportunityEntryId: number | string
): Promise<PurchaseOpportunityEntryRecordDTO> =>
  (await RequestixClient.get(`/purchaseOpportunity/${purchaseOpportunityId}/entry/${purchaseOpportunityEntryId}`)).data
    .entity;

export const getPurchaseEntries = async (): Promise<PurchaseOpportunityEntryRecordDTO[]> =>
  (await RequestixClient.get(`/purchaseOpportunity/entries`)).data.entity;

export const runPurchaseSelection = async (purchaseOpportunityId: number | string): Promise<any> =>
  (await RequestixClient.post(`/admin/purchaseOpportunity/${purchaseOpportunityId}/selection`)).data;

export const sendPurchaseEmails = async (purchaseOpportunityId: number | string): Promise<any> =>
  (await RequestixClient.post(`/admin/purchaseOpportunity/${purchaseOpportunityId}/emails`)).data;

export const getAllPurchaseOpportunityStatuses = async (): Promise<PurchaseOpportunityStatus[]> =>
  (await RequestixClient.get(`/admin/purchaseOpportunity/status`)).data.entity;

export const getLastChargeForPurchaseEntry = async (purchaseOpportunityId: number): Promise<TransactionHistory> =>
  (await RequestixClient.get(`/admin/purchaseOpportunity/latestCharge/${purchaseOpportunityId}`)).data.entity;

export const updatePurchaseOpportunityEntry = async (
  entry: AdminPurchaseOpportunityEntryDTO
): Promise<AdminPurchaseOpportunityEntryDTO> =>
  (await RequestixClient.put(`/admin/purchaseOpportunity/entry`, entry)).data.entity;

export const deletePurchaseOpportunity = async (purchaseOpportunityEntryId: number | string): Promise<void> =>
  (await RequestixClient.delete(`/purchaseOpportunity/${purchaseOpportunityEntryId}`)).data;

export const disablePurchaseOpportunityGame = async (purchaseOpportunityGameId: number | string): Promise<void> =>
  (await RequestixClient.put(`/admin/purchaseOpportunity/disable/game/${purchaseOpportunityGameId}`)).data;

export const fetchAdminSuiteRequests = async (
  startDate: string,
  endDate: string,
  homeTeamId?: number,
  statusId?: number
): Promise<AdminSuiteRequestsDTO> => {
  const homeTeam: string = homeTeamId ? `&homeTeamId=${homeTeamId}` : "";
  const status: string = statusId ? `&statusId=${statusId}` : "";
  return (
    await RequestixClient.get(`/admin/suite/requests?startDate=${startDate}&endDate=${endDate}${homeTeam}${status}`)
  ).data.entity;
};

export const fetchAdminSuiteRequest = async (suiteRequestId: string | number): Promise<AdminSuiteRequest> =>
  (await RequestixClient.get(`/admin/suite/request/${suiteRequestId}`)).data.entity;

export const saveAdminSuiteRequest = async (adminSuiteRequest: AdminSuiteRequest): Promise<AdminSuiteRequest> =>
  (await RequestixClient.put(`/admin/suite/request`, adminSuiteRequest)).data.entity;

export const sendSuiteConfirmationEmailTicketReminder = async (suiteRequestId: number): Promise<any> =>
  (await RequestixClient.post(`/email/suite/${suiteRequestId}/confirmation`)).data;

export const sendSuiteDigitalTickets = async (adminSuiteRequest: AdminSuiteRequest): Promise<SuiteRequestDtdMapping> =>
  (await RequestixClient.post(`/admin/suite/request/tickets/send`, adminSuiteRequest)).data.entity;

export const revokeSuiteDigitalTickets = async (
  adminSuiteRequest: AdminSuiteRequest
): Promise<SuiteRequestDtdMapping> =>
  (await RequestixClient.post(`/admin/suite/request/tickets/revoke`, adminSuiteRequest)).data.entity;

export const deleteSuiteRequest = async (suiteRequestId: number): Promise<any> =>
  (await RequestixClient.delete(`/admin/suite/request/${suiteRequestId}`)).data;

export const getAllDayOfRequestStatuses = async (): Promise<DayOfRequestStatus[]> =>
  (await RequestixClient.get("/dayOf/statuses")).data.entity;

export const forwardRequestDigitalTickets = async (actionStateDto: ActionStateDTO): Promise<any> =>
  (await RequestixClient.post(`/admin/digitalTickets/${actionStateDto.requestId}/forward`, actionStateDto)).data.entity;

export const revokeRequestDigitalTickets = async (actionStateDto: ActionStateDTO): Promise<any> =>
  (await RequestixClient.post(`/admin/digitalTickets/${actionStateDto.requestId}/revoke`, actionStateDto)).data.entity;

export const sendDigitalTicketsSms = async (requestId: number): Promise<any> =>
  (await RequestixClient.post(`/admin/digitalTickets/sms/send/${requestId}`)).data;
