import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getDepartments, getDepartmentsBySeason } from "../api/RequesTixApi";

export const departments = createQueryKeys("departments", {
  getDepartments: () => ({
    queryKey: ["departments"],
    queryFn: () => getDepartments(),
  }),
  getDepartmentsBySeason: (seasonId: number | string) => ({
    queryKey: ["departments", seasonId],
    queryFn: () => getDepartmentsBySeason(seasonId),
  }),
});
