import React from "react";
import styled from "styled-components";
import { BoxOfficeTicketDTO } from "../../types/Tickets";
import { createCssVariableString, Typography } from "best-common-react";

type TicketStyleProps = {
  failed: boolean;
};

const Ticket = styled.div<TicketStyleProps>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0.5rem;
  border: 1px solid
    ${(props) =>
      props.failed
        ? createCssVariableString("coreColorsOnColoredSurfaceRed")
        : createCssVariableString("outlineColorsOutlineVariant")};
  background: ${(props) =>
    props.failed
      ? createCssVariableString("coreColorsOnColoredSurfaceRed")
      : createCssVariableString("surfaceColorsSurfaceContainerLowest")};
  color: ${(props) =>
    props.failed
      ? createCssVariableString("coreColorsOnColoredSurfaceRed")
      : createCssVariableString("onColorsOnSurface")};
`;

const TitleValue = ({ title, value, classes = "" }) => (
  <div className={`d-flex flex-column bcr-px-2 bcr-py-1 ${classes}`}>
    <Typography>{!!value ? value : <>&nbsp;</>}</Typography>
    <Typography>{title}</Typography>
  </div>
);

type TicketDisplayProps = {
  data: BoxOfficeTicketDTO;
};

const TicketDisplay = ({ data }: TicketDisplayProps) => {
  if (!data) {
    return <div />;
  } else if (data.parking) {
    return (
      <Ticket key={data.ticketID} failed={data.failed}>
        <TitleValue value={data.section} title="Parkig Lot" />
        <TitleValue value={data.price} title="price" />
      </Ticket>
    );
  } else {
    return (
      <Ticket key={data.ticketID} failed={data.failed}>
        <TitleValue value={data.section} title="sec" />
        <TitleValue value={data.row} title="row" />
        <TitleValue value={data.seat} title="seat" />
        <TitleValue value={data.price} title="price" />
      </Ticket>
    );
  }
};

export default TicketDisplay;
