import {
  Button,
  DateRange,
  DateRangeInput,
  DATE_FORMATTER_STR,
  DATE_FORMAT_STR,
  formatDate,
  Column,
  getRawDate,
  isBefore,
  Row,
  Select,
  sort,
  useLoading,
  ValueOpt,
  StatsTeam,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import {
  checkForSeasonTickets,
  getDepartments,
  getSeasonTickets,
  getUnusedSeasonTickets,
} from "../../../api/RequesTixApi";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { Department } from "../../../types/Department";
import { UserTicketRequestDTO } from "../../../types/UserRequest";
import { previousMonth, yesterday } from "../../../util/DateObjUtil";
import { getDepartmentOption } from "../../../util/DropdownUtil";
import { downloadLink, FileTypes } from "../../../util/FileUtil";
import { ReportHandlerTypeProps } from "../ReportHandler";
import SeasonTicketsReportDataTable from "./SeasonTicketsReportDataTable";

const SeasonTicketsReport = ({ reportData, setReportData }: ReportHandlerTypeProps) => {
  const { setLoading } = useLoading();
  const { teamsOptions } = useDropdowns();
  const { startDate, endDate, homeTeamId, departmentId } = reportData;
  const [dateRange, setDateRange] = useState<DateRange<Date>>({
    start: !!startDate ? getRawDate(startDate, DATE_FORMAT_STR) : previousMonth,
    end: !!endDate ? getRawDate(endDate, DATE_FORMAT_STR) : yesterday,
  });
  const [displayTeamOptions, setDisplayTeamOptions] = useState<ValueOpt<StatsTeam>[]>([]);
  const [seasonTickets, setSeasonTickets] = useState<UserTicketRequestDTO[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const onChange = (key: string, value: any) => {
    setReportData({
      ...reportData,
      [key]: value,
    });
  };

  const updateDate = ({ start, end }: DateRange<Date>) => {
    if (!!start && !!end) {
      setReportData({
        ...reportData,
        startDate: formatDate(start, DATE_FORMATTER_STR),
        endDate: formatDate(end, DATE_FORMATTER_STR),
      });
    }
  };

  const getData = async (
    teamId: number | undefined,
    startDate: Date,
    endDate: Date,
    departmentId: number | undefined
  ) => {
    try {
      setLoading(true);
      if (!!teamId && !!startDate && !!endDate && isBefore(startDate, endDate, DATE_FORMAT_STR)) {
        const results: UserTicketRequestDTO[] = await getSeasonTickets(teamId, startDate, endDate, departmentId);
        setSeasonTickets(
          results.map((r: UserTicketRequestDTO) => ({
            ...r,
            id: r.requestId,
          }))
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const downloadUnusedReport = async () => {
    try {
      setLoading(true);
      if (!!homeTeamId && !!startDate && !!endDate && isBefore(startDate, endDate)) {
        const response = await getUnusedSeasonTickets(homeTeamId, startDate, endDate);
        downloadLink(response, FileTypes.EXCEL);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const checkForSeasonTicketsCall = async () => {
    try {
      setLoading(true);
      if (!!homeTeamId && !!startDate && !!endDate && isBefore(startDate, endDate)) {
        await checkForSeasonTickets(homeTeamId, startDate, endDate);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const updateDateRange = (newDate: DateRange<Date>) => {
    setDateRange(newDate);
    setReportData({
      ...reportData,
      startDate: formatDate(newDate.start, DATE_FORMATTER_STR),
      endDate: formatDate(newDate.end, DATE_FORMATTER_STR),
    });
  };

  useEffect(() => {
    const newDate: DateRange<Date> = {
      start: startDate ? getRawDate(startDate, DATE_FORMAT_STR) : previousMonth,
      end: endDate ? getRawDate(endDate, DATE_FORMAT_STR) : yesterday,
    };
    updateDateRange(newDate);
    void getData(homeTeamId, newDate.start, newDate.end, departmentId);
  }, [homeTeamId, startDate, endDate, departmentId]);

  useEffect(() => {
    setDisplayTeamOptions(teamsOptions.filter((team) => team.label !== "All"));
  }, [teamsOptions]);

  useEffect(() => {
    getDepartments().then((data: Department[]) => {
      setDepartmentOptions(
        [
          {
            label: "All",
            value: {},
          },
        ].concat(
          sort(
            data.map((d: Department) => ({
              label: d.departmentName,
              value: d,
            })),
            "label",
            1
          )
        )
      );
    });
  }, []);

  return (
    <>
      <Row>
        <Column width={4}>
          <div className="form-group">
            <Select
              id="home-team-dropdown"
              label="Home Team"
              options={displayTeamOptions}
              value={displayTeamOptions.find((opt: ValueOpt<StatsTeam>) => {
                if (!!homeTeamId) {
                  return opt.value.id === homeTeamId;
                } else {
                  return !opt.value.id;
                }
              })}
              onChange={(team: ValueOpt<StatsTeam>) => onChange("homeTeamId", team.value.id || null)}
              gutterBottom
            />
          </div>
        </Column>
        <Column width={4}>
          <DateRangeInput
            id="game-date"
            label="Game Date"
            value={dateRange}
            onChange={updateDate}
            maxRange={{
              months: 3,
            }}
            withPortal
            gutterBottom
          />
        </Column>
        <Column width={4}>
          <Select
            id="department"
            label="Department"
            options={departmentOptions}
            value={getDepartmentOption(departmentId, departmentOptions)}
            onChange={(value) => {
              onChange("departmentId", value.value.departmentId);
            }}
            gutterBottom
          />
        </Column>
      </Row>
      <div className="d-flex">
        <Button variant="default" disabled={!homeTeamId} onClick={downloadUnusedReport} className="bcr-me-2">
          Download Unused Report
        </Button>
        <Button variant="default" disabled={!homeTeamId} onClick={checkForSeasonTicketsCall}>
          Check for season tickets
        </Button>
      </div>
      <SeasonTicketsReportDataTable data={seasonTickets} reportData={reportData} />
    </>
  );
};

export default SeasonTicketsReport;
