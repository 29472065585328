import {
  Button,
  Card,
  Column,
  Row,
  Search,
  Select,
  sort,
  StickyFooterButtons,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useMemo, useState } from "react";
import { updateUserAllotment } from "../../../api/RequesTixApi";
import UserAllotmentBulkUpdate from "../../../components/user/UserAllotmentBulkUpdate";
import UserTable from "../../../components/user/UserTable";
import { useAdminYear } from "../../../contexts/AdminYearContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { useMetadata } from "../../../contexts/MetadataContext";
import { AllotmentDTO, UserAllotmentDTO } from "../../../types/Allotment";
import { Department } from "../../../types/Department";
import { Season } from "../../../types/Season";
import { UserDTO } from "../../../types/User";
import AdminCreateUser from "./AdminCreateUser";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queries } from "../../../queries";

const AdminUsers = () => {
  const { loading, setLoading } = useLoading();
  const { activeSeason } = useMetadata();
  const { seasonsOptions } = useDropdowns();
  const [, setSelectedYear] = useAdminYear();
  const queryClient = useQueryClient();

  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<ValueOpt<Department | undefined>>({
    label: "All",
    value: undefined,
  });
  const [selectedSeason, setSelectedSeason] = useState<ValueOpt<Season | undefined>>({
    label: "",
    value: undefined,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [updateAllotmentOpen, setUpdateAllotmentOpen] = useState<boolean>(false);
  const [createUserOpen, setCreateUserOpen] = useState<boolean>(false);

  const { data: departments = [] } = useQuery<Department[]>({
    ...queries.departments.getDepartments(),
  });

  // Transform departments into options
  const departmentOptions = useMemo(() => {
    return [
      { label: "All", value: undefined } as ValueOpt<undefined>,
      ...sort(
        departments.map(
          (department: Department) => ({ label: department.departmentName, value: department } as ValueOpt<Department>)
        ),
        "label"
      ),
    ];
  }, [departments]);

  const { data: users = [], isLoading: isUsersLoading } = useQuery<UserDTO[]>({
    ...queries.user.getUsers(selectedDepartment?.value?.departmentId, selectedSeason?.value?.seasonId),
    enabled: !!selectedSeason?.value?.seasonId,
  });

  const updateAllotment = async (allotment: AllotmentDTO[]) => {
    try {
      setLoading(true);
      const userAllotments: UserAllotmentDTO[] = selectedUsers.map((userId: number) => ({
        userId: userId,
        allotment: allotment,
      }));
      await updateUserAllotment(userAllotments);
      setUpdateAllotmentOpen(false);
      setSelectedUsers([]);
      await queryClient.invalidateQueries({ queryKey: queries.user._def });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setUpdateAllotmentOpen(false);
    setSelectedUsers([]);
  };

  useEffect(() => {
    if (!!seasonsOptions.length) {
      setSelectedSeason(seasonsOptions.find((season) => season.value.seasonId === activeSeason.seasonId) || undefined);
    }
  }, [seasonsOptions]);

  useEffect(() => {
    if (selectedSeason?.value?.seasonId) {
      setSelectedYear({ label: selectedSeason.value.year.toString(), value: selectedSeason.value.year });
    }
  }, [selectedSeason]);

  return (
    <Card>
      <Card.Header>Users</Card.Header>
      <Card.Body>
        {updateAllotmentOpen && (
          <UserAllotmentBulkUpdate
            isOpen={updateAllotmentOpen}
            onClose={onClose}
            onSave={updateAllotment}
            year={selectedSeason.value.year}
            userCount={selectedUsers.length}
          />
        )}
        <AdminCreateUser
          show={createUserOpen}
          close={async (saved: boolean) => {
            if (saved) {
              await queryClient.invalidateQueries({ queryKey: queries.user._def });
            }
            setCreateUserOpen(false);
          }}
          departments={departmentOptions}
        />
        <Row>
          <Column width={4}>
            <Select
              id="seasons-dropdown"
              label="Seasons"
              options={seasonsOptions}
              value={selectedSeason}
              onChange={(season) => setSelectedSeason(season)}
              gutterBottom
            />
          </Column>
          <Column width={4}>
            <Select
              id="departments-dropdown"
              label="Departments"
              options={departmentOptions}
              value={selectedDepartment}
              onChange={(department) => setSelectedDepartment(department)}
              gutterBottom
            />
          </Column>
          <Column width={4}>
            <Search
              id="search-box"
              label="Search Users"
              placeholder="begin typing to filter users"
              value={searchTerm}
              onChange={setSearchTerm}
              gutterBottom
            />
          </Column>
        </Row>
        <UserTable
          values={users}
          selected={selectedUsers}
          setSelected={setSelectedUsers}
          searchTerm={searchTerm}
          loading={loading || isUsersLoading}
        />
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="default" onClick={() => setCreateUserOpen(true)} className="bcr-me-2">
            Create User
          </Button>
          <Button variant="default" disabled={!selectedUsers.length} onClick={() => setUpdateAllotmentOpen(true)}>
            Update Allotments
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminUsers;
