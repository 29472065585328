import React from "react";
import styled from "styled-components";

const Denied = styled.div.attrs(() => ({
  className: "bcr-mt-3",
}))`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
`;

const PermissionDenied = () => <Denied>You currently do not have access to this application</Denied>;

export default PermissionDenied;
