import { Button, Input, Modal } from "best-common-react";
import React from "react";
import { createBusiness, graphQl } from "../../../api/RequesTixApi";
import { Business } from "../../../types/Business";

const createSingleQuery = (businessName) => `{
  getBusinessByName(businessName: "${businessName}") {
    businessId,
    businessName
  }
}
`;

type AddBusinessModalProps = {
  isOpen: boolean;
  onClose: () => void;
  setSelectedBusiness: (value: Business) => void;
};

const AddBusinessModal: React.FC<AddBusinessModalProps> = ({ isOpen, onClose, setSelectedBusiness }) => {
  const [name, setName] = React.useState<string>("");
  const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);

  const getBusinessByName = (query) => {
    const queryObj = {
      query: createSingleQuery(query),
      variables: null,
      operationName: null,
    };
    return graphQl(queryObj);
  };

  const close = () => {
    setName("");
    onClose();
  };

  const save = () => {
    createBusiness(name).then((res) => {
      setSelectedBusiness(res);
      close();
    });
  };

  React.useEffect(() => {
    getBusinessByName(name).then((data) => setIsDuplicate(!!data.getBusinessByName));
  }, [name]);

  return (
    <Modal show={isOpen} size="lg">
      <Modal.Header close={onClose}>Add a business</Modal.Header>
      <Modal.Body>
        <Input
          id="business-name-input"
          label="Business Name"
          required
          gutterBottom
          placeholder="enter a unique business name"
          value={name}
          onChange={(value) => setName(value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="bcr-me-2" disabled={name.length === 0 || isDuplicate} onClick={save}>
          Save
        </Button>
        <Button variant="default" onClick={close}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBusinessModal;
