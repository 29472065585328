import {
  Card,
  ConfirmationModal,
  DateRange,
  DateRangeInput,
  DATE_FORMATTER_STR,
  formatDate,
  Column,
  NotApplicable,
  Row,
  Select,
  Typography,
  useLoading,
  ValueOpt,
  StatsTeam,
  GameInfo,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { deleteDayOfTicketRequest, getDayOfRequests } from "../../../api/RequesTixApi";
import AdminActiveTodaysTicketsTable from "../../../components/todaysTickets/admin/AdminActiveTodaysTicketsTable";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { DayOfGameRequestDTO, DayOfRequestStatus } from "../../../types/TodaysTickets";
import { nextMonthStr, todayStr } from "../../../util/DateObjUtil";
import { updateRouteParams, useRouteParams } from "../../../util/RouteUtil";
import { getTeamOption } from "../../../util/TeamUtil";
import { formatSubmittedDate } from "../../../util/TimeUtil";
import TodaysTicketsDataTable, { DayOfTableData } from "./TodaysTicketsDataTable";
import { useLocation, useNavigate } from "react-router-dom";

const AdminTodaysTickets = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { teamsOptions, dayOfRequestStatusOptions } = useDropdowns();
  const { loading, setLoadingNonBlocking } = useLoading();
  const [requests, setRequests] = useState<DayOfTableData[]>([]);
  const [displayedRequests, setDisplayedRequests] = useState<DayOfTableData[]>([]);
  const [toBeDeleted, setToBeDeleted] = useState<DayOfTableData | undefined>(undefined);

  const onDeleteClicked = (_index: number, value: DayOfTableData) => {
    setToBeDeleted(value);
  };

  const { startDate = todayStr, endDate = nextMonthStr, teamId, statusId } = useRouteParams(location.search);

  const dateRange: DateRange<Date> = {
    start: new Date(Date.parse(startDate)),
    end: new Date(Date.parse(endDate)),
  };

  const selectedHomeTeam = getTeamOption(teamId, teamsOptions);
  const selectedStatusOption = !!statusId
    ? dayOfRequestStatusOptions.find((val) => val.value.dayOfRequestStatusId === parseInt(statusId))
    : undefined;

  const updateUrl = ({ startDate, endDate, teamId, statusId }) => {
    updateRouteParams(navigate, RouteConstants.ADMIN.TODAYS_TICKETS, {
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      statusId: statusId,
    });
  };

  const getDayOfGamesRequestsCall = async (startDate, endDate, teamId) => {
    try {
      setLoadingNonBlocking(true);
      const res: DayOfGameRequestDTO[] = await getDayOfRequests(startDate, endDate, teamId);
      const data: DayOfTableData[] = res.map((req: DayOfGameRequestDTO) => ({
        dayOfRequestId: req.dayOfRequestId,
        editLink: urlReplace(RouteConstants.ADMIN.TODAYS_TICKETS_EDIT, { requestId: req.dayOfRequestId }),
        firstName: req.user.firstName,
        lastName: req.user.lastName,
        ballparkEmail: req.emailAddress,
        numberOfTickets: req.numberOfTickets,
        game: req.game,
        ticketsSentDisplay: req.ticketSentDisplay,
        status: req.status,
        requestTime: formatSubmittedDate(req.requestedDate),
        statusId: req.statusId,
      }));
      setRequests(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingNonBlocking(false);
    }
  };

  const updateDate = (dateRange: DateRange<Date>) => {
    updateUrl({
      startDate: formatDate(dateRange.start, DATE_FORMATTER_STR),
      endDate: formatDate(dateRange.end, DATE_FORMATTER_STR),
      teamId: teamId,
      statusId: statusId,
    });
  };

  const deleteRequest = async (toBeDeleted: DayOfTableData) => {
    try {
      setLoadingNonBlocking(true);
      await deleteDayOfTicketRequest(toBeDeleted.dayOfRequestId);
      setToBeDeleted(undefined);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingNonBlocking(false);
    }
  };

  useEffect(() => {
    if (!!statusId) {
      setDisplayedRequests(requests.filter((req: DayOfTableData) => req.statusId === parseInt(statusId)));
    } else {
      setDisplayedRequests(requests);
    }
  }, [requests, statusId]);

  useEffect(() => {
    void getDayOfGamesRequestsCall(startDate, endDate, teamId);
  }, [startDate, endDate, teamId]);

  return (
    <Card>
      <Card.Header>Today's Tickets Requests</Card.Header>
      <Card.Body>
        {!!toBeDeleted && (
          <ConfirmationModal
            show={!!toBeDeleted}
            onCancel={() => setToBeDeleted(undefined)}
            onConfirm={() => {
              void deleteRequest(toBeDeleted);
            }}
            header="Delete Today's Ticket Request"
            body={
              <Typography>
                <div className="bcr-mb-2">
                  Are you sure you want to delete the request for {toBeDeleted.firstName} {toBeDeleted.lastName} for the
                  game
                </div>
                <GameInfo
                  gameDate={toBeDeleted.game.gameDate}
                  venue={toBeDeleted.game.venue}
                  away={toBeDeleted.game.teams.away.team}
                  home={toBeDeleted.game.teams.home.team}
                  seriesDescription={toBeDeleted.game.seriesDescription}
                />
              </Typography>
            }
            loading={loading}
          />
        )}
        <Row>
          <Column width={4}>
            <DateRangeInput
              label="Date Range"
              id="todays-tickets-date-range"
              value={dateRange}
              onChange={(value: DateRange<any> | NotApplicable) => {
                updateDate(value as DateRange<Date>);
              }}
              maxRange={{
                months: 1,
              }}
              withPortal
              popoverPlacement="bottom-end"
            />
          </Column>
          <Column width={4}>
            <Select
              id="hometeam-dropdown"
              label="Home Team"
              options={teamsOptions}
              value={selectedHomeTeam}
              onChange={(team: ValueOpt<StatsTeam>) =>
                updateUrl({ teamId: team.value.id, startDate, endDate, statusId })
              }
            />
          </Column>
          <Column width={4}>
            <Select
              id="RequestStatus"
              label="Request Status"
              options={dayOfRequestStatusOptions}
              value={selectedStatusOption}
              onChange={(value: ValueOpt<DayOfRequestStatus>) =>
                updateUrl({ teamId, startDate, endDate, statusId: value?.value?.dayOfRequestStatusId })
              }
              clearable={true}
            />
          </Column>
        </Row>
        <TodaysTicketsDataTable
          requests={displayedRequests}
          hasDelete
          hasEdit
          onDeleteClicked={onDeleteClicked}
          loading={loading}
        />
        <AdminActiveTodaysTicketsTable />
      </Card.Body>
    </Card>
  );
};

export default AdminTodaysTickets;
