import {
  Accordion,
  AttributeHeader,
  AttributeValue,
  CustomMobileAccordionType,
  Link,
  MatchUp,
} from "best-common-react";
import React from "react";
import styled from "styled-components";
import RouteConstants, { urlReplace } from "../../constants/RouteConstants";
import { useTimezone } from "../../util/TimeUtil";
import { EventTableData, IsActiveFormatter, OnOffFormatter } from "./EventsTable";

const Header = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.palette.onColorsOnSurfaceLow};
`;

const Override = styled.div`
  font-size: 0.6875rem;
  color: ${(props) => props.theme.palette.onColorsOnSurfaceLow};
  font-weight: 300;
  font-style: oblique;
`;

const EventsAccordion = ({ data, ...rest }: CustomMobileAccordionType<EventTableData>) => {
  const { id, location, game, date: gameDate, hasOverrides, venue, venueBlackedOut, eventBlackedOut } = data;
  const { time, day, date } = useTimezone(gameDate.gameDate, gameDate.tz, game.status);
  return (
    <Accordion {...rest}>
      <Accordion.Header>
        <div className="d-flex justify-content-between">
          <Link
            to={{
              pathname: urlReplace(RouteConstants.ADMIN.EVENTS_EDIT, { eventId: id }),
            }}
            state={{ previousLocation: location }}
          >
            <Header>{date}</Header>
          </Link>
          <MatchUp home={game.home} away={game.away} description={game.seriesDescription} />
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <Description>
            {day}
            <span className="bcr-ms-2">{time}</span>
          </Description>
          {hasOverrides && <Override>override(s)</Override>}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div>
          <AttributeHeader>Venue</AttributeHeader>
          <AttributeValue>{venue}</AttributeValue>
        </div>
        <div>
          <AttributeHeader>Venue Requests</AttributeHeader>
          <AttributeValue>
            <OnOffFormatter value={venueBlackedOut} />
          </AttributeValue>
        </div>
        <div>
          <AttributeHeader>Game Blackout</AttributeHeader>
          <AttributeValue>
            <IsActiveFormatter value={eventBlackedOut} />
          </AttributeValue>
        </div>
      </Accordion.Body>
    </Accordion>
  );
};

export default EventsAccordion;
