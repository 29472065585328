import {
  ASC,
  DataTable,
  DataTableColumn,
  DateTimeFormatter,
  FormatterType,
  Icon,
  Link,
  SortDirection,
  SortFilters,
  StatsGameFormatter,
  tableSort,
  useTheme,
} from "best-common-react";
import { Location } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { APPROVED, DENIED, IN_REVIEW, OPEN } from "../../../constants/RequestTableConstants";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { UserTicketRequestDTO } from "../../../types/UserRequest";
import { getStatusColor, removeRequestId } from "../../../util/TicketRequestUtil";
import MyRequestAccordion from "./MyRequestAccordion";
import { RequestixLocationState } from "../../../types/Routing";

export type FlattenedUserTicketRequestDTO = UserTicketRequestDTO & {
  location: Location<RequestixLocationState>;
  gameDate: Date;
  statusName: string;
  fulfillmentType: string;
};

const EditExpansionFormatter = ({
  row: { requestId, status, pastCutoff, location },
}: FormatterType<FlattenedUserTicketRequestDTO>) => {
  switch (status.requestStateId) {
    case OPEN:
      return !pastCutoff ? (
        <Link
          to={{
            pathname: urlReplace(RouteConstants.REQUESTS.EDIT, { requestId: requestId }),
          }}
          state={{
            previousLocation: { ...location },
            search: removeRequestId(location),
          }}
        >
          <Icon iconName="fa-pencil-alt" />
        </Link>
      ) : null;
    case APPROVED:
    case DENIED:
    // return <DataTable.ExpandButton expanded={expanded} onClick={expand} />;
    case IN_REVIEW:
    default:
      return null;
  }
};

const Columns: DataTableColumn<FlattenedUserTicketRequestDTO>[] = [
  {
    name: "",
    key: "requestId",
    width: 30,
    readonlyFormatter: EditExpansionFormatter,
  },
  {
    name: "Status",
    key: "statusName",
    width: 120,
    sortable: true,
  },
  {
    name: "Game",
    key: "game",
    minWidth: 340,
    readonlyFormatter: StatsGameFormatter,
    sortable: true,
  },
  {
    name: "Recipient",
    key: "recipient",
    width: 150,
    sortable: true,
  },
  {
    name: "Qty",
    key: "quantity",
    cellClass: "text-center",
    width: 100,
    sortable: true,
  },
  {
    name: "Category",
    key: "category",
    width: 120,
    sortable: true,
  },
  {
    name: "Business",
    key: "business",
    width: 120,
    sortable: true,
  },
  {
    name: "Project",
    key: "project",
    width: 150,
    sortable: true,
  },
  {
    name: "Fulfillment",
    key: "fulfillmentType",
    width: 180,
    sortable: true,
  },
  {
    name: "Submitted",
    key: "submitted",
    readonlyFormatter: DateTimeFormatter,
    minWidth: 180,
    sortable: true,
  },
];

type MyRequestsTableProps = {
  location: Location<RequestixLocationState>;
  requests: UserTicketRequestDTO[];
  activeGamePk?: number;
  size?: number;
};

const MyRequestsTable = ({ requests = [], location, activeGamePk, size }: MyRequestsTableProps) => {
  const { Theme } = useTheme();
  const [data, setData] = useState<FlattenedUserTicketRequestDTO[]>([]);
  const [sortedData, setSortedData] = useState<FlattenedUserTicketRequestDTO[]>([]);
  const [sortFilters, setSortFilters] = useState<SortFilters<FlattenedUserTicketRequestDTO>>({
    key: "gameDate",
    direction: ASC,
  });
  const [scrollToIndex, setScrollToIndex] = useState<number | undefined>(undefined);

  const rowStylesGetter = (_index: number, value: UserTicketRequestDTO) => {
    const request = value;
    //@ts-ignore
    const activeRequest = activeGamePk && parseInt(request.gamePk) === parseInt(activeGamePk);
    if (request) {
      const color = getStatusColor(Theme, request.requestId, request.status.requestStateId, activeRequest);
      return { background: color, transition: "background-color 2s ease 2s" };
    } else {
      return {};
    }
  };

  useEffect(() => {
    setSortedData(tableSort(data, sortFilters));
  }, [data, sortFilters]);

  useEffect(() => {
    setData(
      //@ts-ignore
      requests.map((r: UserTicketRequestDTO) => ({
        ...r,
        gameDate: r.game.gameDate,
        statusName: r.status.requestStateName,
        fulfillmentType: r.requestFulfillmentType.requestFulfillmentTypeName,
        location: location,
      }))
    );
  }, [requests]);

  useEffect(() => {
    if (requests.length && activeGamePk) {
      const index = requests.map((r) => r.gamePk).indexOf(activeGamePk);
      if (index) {
        setScrollToIndex(index);
      }
    }
  }, [requests, activeGamePk]);

  return (
    <>
      {!!requests.length && (
        <DataTable
          data={sortedData}
          columns={Columns}
          accordion={MyRequestAccordion}
          rowStylesGetter={rowStylesGetter}
          sortColumn={sortFilters.key}
          sortDirection={sortFilters.direction}
          sortFunction={(key, direction: SortDirection) => setSortFilters({ key, direction })}
          displayExpansionButton={false}
          scrollToIndex={scrollToIndex}
          statusTextOverride={() =>
            requests.length === size ? `${size} requests` : `${requests.length} of ${size} requests`
          }
          tableHeights={{
            autogrow: true,
            maxHeightOffset: 400,
          }}
        />
      )}
    </>
  );
};

export default MyRequestsTable;
