import {
  DataTable,
  DataTableColumn,
  ExpansionType,
  PriceFormatter,
  SortDirection,
  SortFilters,
  StatsGameFormatter,
  tableSort,
  useLoading,
  useTheme,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { getProjectRequests } from "../../../api/RequesTixApi";
import { UserTicketRequestDTO } from "../../../types/UserRequest";
import { getStatusColor, requestsFreeTextSearch } from "../../../util/TicketRequestUtil";
import { ReportHandlerTypeProps } from "../ReportHandler";
import ProjectRequestsByProjectAccordion from "./ProjectRequestsByProjectAccordion";
import ProjectRequestDetails from "./ProjectRequestsDetails";
import { ProjectUserTicketRequestDTO } from "./ProjectRequestsReport";
import ProjectRequestsSummaryTable from "./ProjectRequestsSummaryTable";

const Expansion = ({ value }: ExpansionType<ProjectUserTicketRequestDTO>) => (
  <div className="bcr-py-2 bcr-px-3 w-100">
    <ProjectRequestDetails value={value} />
  </div>
);

const Columns: DataTableColumn<ProjectUserTicketRequestDTO>[] = [
  {
    name: "Status",
    key: "statusName",
    sortable: true,
    width: 100,
  },
  {
    name: "Requester",
    key: "requester",
    sortable: true,
    minWidth: 180,
  },
  {
    name: "Game",
    key: "game",
    sortable: true,
    readonlyFormatter: StatsGameFormatter,
    width: 340,
  },
  {
    name: "Qty",
    key: "quantity",
    sortable: true,
    width: 100,
    cellClass: "justify-content-center",
  },
  {
    name: "Business",
    key: "business",
    sortable: true,
    width: 180,
  },
  {
    name: "Fulfillment",
    key: "fulfillmentType",
    sortable: true,
    width: 175,
  },
  {
    name: "Total Ticket Cost",
    key: "totalTicketCost",
    sortable: true,
    readonlyFormatter: PriceFormatter,
    width: 200,
    cellClass: "justify-content-center",
  },
  {
    name: "Home Team",
    key: "homeTeam",
    sortable: true,
    width: 200,
  },
];

const ProjectRequestsByProject = ({ reportData, setCanExport, location }: ReportHandlerTypeProps) => {
  const { projectId, startDate, endDate, statusId, fulfillmentTypeId } = reportData;
  const { Theme } = useTheme();
  const { setLoading } = useLoading();
  const [data, setData] = useState<ProjectUserTicketRequestDTO[]>([]);
  const [sortedData, setSortedData] = useState<ProjectUserTicketRequestDTO[]>([]);
  const [sortFilters, setSortFilters] = useState<SortFilters<ProjectUserTicketRequestDTO>>({
    key: "gameDate",
    direction: "ASC",
  });

  const onSort = (key: keyof ProjectUserTicketRequestDTO, direction: SortDirection) =>
    setSortFilters({ key, direction });

  const rowStylesGetter = (_index: number, value: ProjectUserTicketRequestDTO) => {
    if (value) {
      const color = getStatusColor(Theme, value.requestId, value.status.requestStateId, false);
      return { background: color, transition: "background-color 2s ease 2s" };
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (projectId && startDate && endDate) {
      setLoading(true);
      getProjectRequests(projectId, startDate, endDate, statusId, fulfillmentTypeId).then(
        (data: UserTicketRequestDTO[]) => {
          if (data) {
            setData(
              //@ts-ignore
              data.map((d: UserTicketRequestDTO) => ({
                ...d,
                gameDate: d.game ? d.game.gameDate : null,
                homeTeam: d.game ? d.game.teams.home.team.name : null,
                statusName: d.status.requestStateName,
                location,
              }))
            );
          }
          setLoading(false);
          setCanExport(!!data.length);
        }
      );
    }
  }, [projectId, startDate, endDate, statusId, fulfillmentTypeId]);

  useEffect(() => {
    const filteredData = requestsFreeTextSearch(data, reportData.search);
    setSortedData(tableSort(filteredData, sortFilters));
  }, [data, sortFilters, reportData.search]);

  return (
    <>
      <div className="bcr-my-2">
        <ProjectRequestsSummaryTable
          projectId={reportData.projectId}
          startDate={reportData.startDate}
          endDate={reportData.endDate}
        />
      </div>
      <DataTable
        data={sortedData}
        columns={Columns}
        accordion={ProjectRequestsByProjectAccordion}
        expansion={Expansion}
        rowStylesGetter={rowStylesGetter}
        sortColumn={sortFilters.key}
        sortDirection={sortFilters.direction}
        sortFunction={onSort}
        statusTextOverride={(selectedCount, totalCount) =>
          selectedCount > 0 ? `${selectedCount} of ${totalCount} requests` : `${totalCount} requests`
        }
        displayExpandAllButton={false}
        virtualized={false}
      />
    </>
  );
};

export default ProjectRequestsByProject;
